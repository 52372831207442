import {
  FeedbackType,
  UserReport,
  OrganizationReport,
  OrganizationEventReport,
  CustomEventReport,
  ReportType,
  MeetingReport,
  UserData,
} from "../types/entity"

import { Flex } from "./Flex"
import styled from "styled-components"
import UserInfo from "./Feedbacks/UserInfo"
import ReportedReason from "./Feedbacks/ReportedText"
import OrganizationInfo from "./Feedbacks/OrganizationInfo"
import PositiveFeedback from "./Feedbacks/PositiveFeedback"
import NegativeFeedback from "./Feedbacks/NegativeFeedback"
import OrganizationEventInfo from "./Feedbacks/OrganizationEventInfo"
import CustomEventInfo from "./Feedbacks/CustomEventInfo"
import ReportedText from "./Feedbacks/ReportedText"

type ReportProps = {
  type: FeedbackType
  report: ReportType
}

const BoldText = styled.div`
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
`

const Report: React.FC<ReportProps> = ({ report, type }) => {
  if (type === FeedbackType.CUSTOM_EVENTS) {
    report = report as CustomEventReport
    return (
      <Flex justify="space-between">
        <CustomEventInfo report={report} />
        <UserInfo user={report.report_author} />
      </Flex>
    )
  }
  if (type === FeedbackType.MEETING) {
    report = report as MeetingReport
    return (
      <>
        <Flex width="100%" justify="space-between">
          <UserInfo user={report.user} />
          <UserInfo user={report.invited_user} />
        </Flex>
        <Flex width="100%" direction="row" align="flex-start" mt="40px">
          <Flex basis="50%" direction="column">
            {report.positive_feedbacks
              .filter(
                (feedback) =>
                  feedback.user_id === (report as MeetingReport).user_id
              )
              .map((feedback, index) => (
                <PositiveFeedback key={index} feedback={feedback} />
              ))}
            {report.negative_feedbacks
              .filter(
                (feedback) =>
                  feedback.user_id === (report as MeetingReport).user_id
              )
              .map((feedback, index) => (
                <NegativeFeedback key={index} feedback={feedback} />
              ))}
          </Flex>
          <Flex basis="50%" direction="column">
            {report.positive_feedbacks
              .filter(
                (feedback) =>
                  feedback.user_id === (report as MeetingReport).invited_user_id
              )
              .map((feedback, index) => (
                <PositiveFeedback key={index} feedback={feedback} />
              ))}
            {report.negative_feedbacks
              .filter(
                (feedback) =>
                  feedback.user_id === (report as MeetingReport).invited_user_id
              )
              .map((feedback, index) => (
                <NegativeFeedback key={index} feedback={feedback} />
              ))}
          </Flex>
        </Flex>
      </>
    )
  }
  if (type === FeedbackType.DELETE) {
    report = report as UserData
    return (
      <Flex direction="column">
        <UserInfo user={report} />
        {report.custom_delete_reason && (
          <BoldText>Custom Reason: {report.custom_delete_reason}</BoldText>
        )}
        <ReportedText
          date={report.deleted_at}
          text={report.delete_reasons.join(",")}
        />
      </Flex>
    )
  }
  if (type === FeedbackType.EVENTS) {
    report = report as OrganizationEventReport

    return (
      <Flex justify="space-between">
        <OrganizationEventInfo report={report} />
        <UserInfo user={report.report_author} />
      </Flex>
    )
  }
  if (type === FeedbackType.ORGANIZATIONS) {
    report = report as OrganizationReport
    return (
      <Flex justify="space-between">
        <OrganizationInfo report={report} />
        <UserInfo user={report.report_author} />
      </Flex>
    )
  }
  if (type === FeedbackType.USERS) {
    report = report as UserReport
    return (
      <>
        <Flex width="100%" justify="space-between">
          <UserInfo user={report.reported_user} />
          <UserInfo user={report.report_author} />
        </Flex>
        <Flex direction="column">
          <BoldText>Причина: {report.report_reason}</BoldText>
          {report.text && <ReportedReason text={report.text} />}
        </Flex>
      </>
    )
  }
  return <div></div>
}

export default Report
