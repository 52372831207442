import styled from "styled-components"
import { UserData } from "../types/entity"
import { Flex } from "./Flex"
import MyImage from "./MyImage"

const UserDescription = styled.div`
  color: #979797;
  font-size: 18px;
`

const UserName = styled.div`
  font-size: 24px;
`

const AvatarWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 14px;
`

const UserInfo: React.FC<{ user: UserData }> = ({ user }) => {
  return (
    <Flex align="flex-start">
      <AvatarWrapper>
        <MyImage
          readOnly
          onUpload={() => {}}
          imgTitle="Avi"
          imgSrc={user.avatar_url || undefined}
        />
      </AvatarWrapper>
      <Flex direction="column" align="flex-start">
        <UserName>{user.first_name}</UserName>
        <UserDescription>{user.description || "-"}</UserDescription>{" "}
        <UserDescription>{user.job || "-"}</UserDescription>
      </Flex>
    </Flex>
  )
}

export default UserInfo
