import React, { useState } from "react"
import styled from "styled-components"

const Container = styled.div<{ border: boolean }>`
  border-radius: inherit;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: ${(props) => (props.border ? "2px solid #ededed" : "")};
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
  }
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: inherit;
`

const CloseImg = styled(Image)`
  width: 20%;
  height: 20%;
  position: absolute;
  top: 50%;
  opacity: 0.3;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
`

type MyImageProps = {
  imgSrc?: string
  imgTitle: string
  readOnly?: boolean
  onUpload(value: Blob | null): void
}

const MyImage: React.FC<MyImageProps> = (props: MyImageProps) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null)

  const onChange = (e: any) => {
    const file = e.target.files[0] as Blob

    if (!file) {
      e.target.value = null
      return
    }

    if (file.type !== "image/jpeg") {
      alert("Выберите файл формата jpeg")
      e.target.value = null
      return
    }
    const reader = new FileReader()
    reader.onloadend = () => {
      setImgSrc(reader.result as string)
    }
    props.onUpload(file)
    reader.readAsDataURL(file)
  }

  const onClose = () => {
    setImgSrc(null)
    props.onUpload(null)
  }

  if (props.readOnly) {
    return (
      <Container border={!imgSrc}>
        {props.imgSrc ? (
          <Image src={imgSrc ? imgSrc : props.imgSrc} alt={props.imgTitle} />
        ) : (
          <span>No {props.imgTitle}</span>
        )}{" "}
      </Container>
    )
  }

  return (
    <Container border={!imgSrc}>
      {imgSrc || props.imgSrc ? (
        <>
          <Image src={imgSrc ? imgSrc : props.imgSrc} alt={props.imgTitle} />
          <CloseImg onClick={onClose} src="/img/close-icon.svg" alt="Close" />
        </>
      ) : (
        <>
          <img style={{ width: 60 }} src="/img/plus-icon.svg" alt="Add" />
          <input onChange={onChange} type="file" />
        </>
      )}{" "}
    </Container>
  )
}

export default MyImage
