import styled from "styled-components"

const InputContainer = styled.div`
  width: 467px;
  height: 60px;
  margin-bottom: 34px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const StyledSelect = styled(InputContainer)`
  select {
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 14px 24px;
    border: 2px solid #ededed;
    border-radius: 30px;
    background: transparent;
    font-size: 20px;
    color: black;
    resize: none;
    font-family: "Open Sans", sans-serif;
    :placeholder {
      color: #979797;
    }
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 1rem;
`

type OptionItem = {
  value: any
  label: string
}

type Props = {
  value: string
  name: string
  onChange: (v: any) => void
  options: OptionItem[]
  title: string
}

export default function CustomSelect(props: Props) {
  const { value, onChange, name, options, title } = props

  return (
    <div>
      <Title>{title}</Title>
      <StyledSelect>
        <select value={value} onChange={onChange} name={name}>
          {!value ? (
            <option disabled value="">
              Не выбрано
            </option>
          ) : null}
          {options.map((op) => (
            <option value={op.value}>{op.label}</option>
          ))}
        </select>
      </StyledSelect>
    </div>
  )
}
