import React, { useEffect, useState } from "react"
import useDebounce from "../hooks/useDebounce"
import styled from "styled-components"
import { EntityItem } from "../types/entity"
import Input from "./Input"
import { getOrbStyle } from "../utils"

const Wrap = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.24;
  cursor: pointer;
`

const Content = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 750px;
  background: white;
  border-radius: 50px;
  padding: 50px 140px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  font-size: 25px;
  color: black;
  cursor: pointer;
  margin-bottom: 20px;
`

const ElementContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  :-webkit-scrollbar {
    display: none;
  }
`

const Element = styled.div`
  font-size: 20px;
  color: #979797;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const SearchError = styled.div`
  color: red;
  padding: 1rem;
  border: 2px solid red;
  font-weight: bold;
`

const StatusOrb = styled.div`
  height: 10px;
  width: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`

interface Props {
  title: string

  onSearch: (search: string) => Promise<EntityItem[]>
  onClose: (item: EntityItem | null) => void
}

export default function SearchWindow(props: Props) {
  const [elements, setElements] = useState<EntityItem[]>([])
  const [searchError, setSearchError] = useState<string>("")
  const [value, setValue] = useState<string>("")
  const debounce = useDebounce(value, 250)

  useEffect(() => {
    setElements([])
  }, [])

  const search = async (query: string) => {
    try {
      const results = await props.onSearch(query)

      setElements(results)
    } catch (e) {
      console.error(`Cannot find`, e)
      setElements([])
      setSearchError(e.message)
    }
  }

  useEffect(() => {
    search(debounce)
  }, [debounce])

  return (
    <Wrap>
      <Background onClick={() => props.onClose(null)} />
      <Content>
        <Header>{props.title}</Header>
        <Input
          hint="Поиск"
          onChange={(val) => {
            setValue(val)
          }}
          marginBottom="30px"
        />
        {searchError ? <SearchError>{searchError}</SearchError> : null}
        <ElementContainer>
          {elements.map((element) => (
            <Element key={element.id} onClick={() => props.onClose(element)}>
              <StatusOrb style={getOrbStyle(element.moderationFlag)} />{" "}
              <div>{element.text}</div>
            </Element>
          ))}
        </ElementContainer>
      </Content>
    </Wrap>
  )
}
