import React, { useContext, useState } from "react"
import UserApi from "../../api/UserApi"
import ActionModal from "../ActionModal"
import MyTextArea from "../MyTextArea"
import AppContext from "../../store/AppContext"
import Button from "../Button"
import styled from "styled-components"

const TextAreaWrapper = styled.div`
  width: 300px;
  height: 100px;
  margin-bottom: 30px;
`
const ModalButton = styled.span`
  margin: 0 50px;
  font-size: 24px;
  cursor: pointer;
`

const Modal: React.FC<{ onClose(): void; onYes(reason: string): void }> = ({
  onClose,
  onYes,
}) => {
  const [reason, setReason] = useState("")
  return (
    <ActionModal onClose={onClose} title="Разблокировать пользователя?">
      <TextAreaWrapper>
        <MyTextArea
          value={reason}
          placeholder="Причина"
          onChange={(e) => setReason(e.target.value)}
        />
      </TextAreaWrapper>
      <ModalButton onClick={onClose}>Нет</ModalButton>
      <ModalButton onClick={() => onYes(reason)}>Да</ModalButton>
    </ActionModal>
  )
}

const UnblockUser: React.FC<{ id: string; active: boolean; reload(): void }> =
  ({ id, active, reload }) => {
    const { setChooseWindow } = useContext(AppContext)
    const unblockRequest = async (reason: string) => {
      try {
        await UserApi.unblockUser(id, reason)
        reload()
      } catch (e) {
        console.log(e)
      } finally {
        setChooseWindow(null)
      }
    }

    const unblockUser = () => {
      setChooseWindow(
        <Modal onClose={() => setChooseWindow(null)} onYes={unblockRequest} />
      )
    }

    return (
      <Button onClick={unblockUser} active={active} text="Разблокировать" />
    )
  }

export default UnblockUser
