import React, { useEffect, useState } from "react"
import styled from "styled-components"
import StatsApi from "../api/StatsApi"
import colors from "../components/ScreenPage/colors"
import Stats from "../components/ScreenPage/Stats"
import Time from "../components/ScreenPage/Time"
import { toastError } from "../toast"
import { ScreenStats } from "../types/entity"

const Wrapper = styled.div`
  background-image: url("${process.env.PUBLIC_URL}/img/stats.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-right: 0px;
  font-family: Suisse-Intl;
  flex: 2;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-basis: 100%;
  padding: 10px 0 40px 40px;
  align-items: flex-end;
`
const TopWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-basis: 124px;
  padding-left: 40px;
`

const Logo = styled.div`
  max-width: 124px;
  background-color: #f0f0f0;
  border-radius: 32px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LeftSide = styled.div`
  display: flex;
  height: 60%;
`

const MiddleSide = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: row;
  flex-wrap: wrap;
  height: 60%;
  box-sizing: border-box;
`
const MiddleSideRow = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-between;
`

const RightSide = styled.div`
  display: flex;
  width: 100%;
  flex-basis: 25%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
`

const ScreenPage: React.FC = () => {
  const [data, setData] = useState<ScreenStats>({
    total_custom_events: 0,
    total_invitations: 0,
    total_matches: 0,
    total_messages: 0,
    total_users: 0,
    total_want_to_go: 0,
    want_to_go_today: 0,
    users_today: 0,
    matches_today: 0,
    messages_today: 0,
    invitations_today: 0,
    custom_events_today: 0,
  })
  useEffect(() => {
    const loadStats = async () => {
      try {
        const response = await StatsApi.getScreenStats()
        setData(response.data)
      } catch (e) {
        toastError(e)
      }
    }

    loadStats()

    const interval = setInterval(loadStats, 120000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <Wrapper>
        <TopWrapper>
          <Logo>
            <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="Logo" />{" "}
          </Logo>
          <Time />
        </TopWrapper>
        <Container>
          <LeftSide>
            <Stats
              basis="100%"
              amount={0}
              totalAmount={2}
              label={"произошло встреч"}
              color={colors.green}
            />
          </LeftSide>
          <MiddleSide>
            <Stats
              basis="100%"
              amount={data.users_today}
              totalAmount={data.total_users}
              label={"людей зарегистрировалось"}
              color={colors.yellow}
            />
            <MiddleSideRow>
              <Stats
                basis="100%"
                amount={data.invitations_today}
                totalAmount={data.total_invitations}
                label={"отправлено приглашений"}
                color={colors.purple}
              />
              <Stats
                basis="100%"
                amount={data.matches_today}
                totalAmount={data.total_matches}
                label={"метчей"}
                color={colors.pink}
              />
            </MiddleSideRow>
          </MiddleSide>
          <RightSide>
            <Stats
              basis="100%"
              amount={data.custom_events_today}
              totalAmount={data.total_custom_events}
              label={"событий создано пользователями"}
              color={colors.brown}
            />
            <Stats
              basis="80%"
              amount={data.messages_today}
              totalAmount={data.total_messages}
              label="Сообщений отправлено"
              color={colors.orange}
            />{" "}
            <Stats
              basis="80%"
              amount={data.want_to_go_today}
              totalAmount={data.total_want_to_go}
              label="Нажатий «Хочу пойти»"
              color={colors.grey}
            />
          </RightSide>
        </Container>
      </Wrapper>
    </>
  )
}

export default ScreenPage
