import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Container = styled.div`
  box-sizing: border-box;
  padding-left: 2rem;
  border: 2px solid #ededed;
  border-radius: 100px;
  background: transparent;
  font-size: 20px;
  line-height: 28px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  height: 100%;
`

type Props = {
  hint: string
  content?: string
  marginBottom?: string

  onChange: () => void
  onGoTo?: () => void
}

const ChangeContainer = styled.div`
  background-color: #000000;
  padding: 1rem;

  color: #ffffff;
  font-weight: bold;
  margin-left: 1rem;
  border-radius: 100px;
  cursor: pointer;
`

export default function ChooseButton(props: Props) {
  const [content, setContent] = useState<string>()

  useEffect(() => {
    setContent(props.content)
  }, [props.content])

  return (
    <Container
      style={{
        color: content ? "black" : "#979797",
        marginBottom: props.marginBottom,
      }}
    >
      {content ? content : props.hint}
      <ChangeContainer onClick={props.onChange}>Изменить</ChangeContainer>
      {props.onGoTo ? (
        <ChangeContainer onClick={props.onGoTo}>Перейти</ChangeContainer>
      ) : null}
    </Container>
  )
}
