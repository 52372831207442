import styled from "styled-components"
import { ImCross, ImLink } from "react-icons/im"
import { BiAward, BiArrowToTop } from "react-icons/bi"
import { EntityItem } from "../types/entity"

type Props = {
  onChange: (value: EntityItem[]) => void
  onClick: (value: EntityItem) => void
  onEditPriority?: (value: EntityItem) => void
  onAdd: () => void
  events: EntityItem[]
  maximumEvents: number
  title: string
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const EventItem = styled.div`
  padding: 1rem 2rem 1rem 0;
  font-weight: bold;
`

const IconContainer = styled.div`
  padding: 1rem;
  cursor: pointer;
`

const EventContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 14px 24px;
    border: 2px solid #ededed;
    border-radius: 30px;
    background: transparent;
    font-size: 20px;
    color: black;
    resize: none;
    font-family: "Open Sans", sans-serif;
  }
  .DayPickerInput {
    width: 100%;
  }
  margin-bottom: 20px;
`

const ActionButton = styled.div`
  border-radius: 16px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  margin-left: 1rem;
`

const RemoveButton = styled(ActionButton)`
  background-color: #c05151;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  :hover {
    border: 2px solid black;
    transition: 0.2s;
  }
`

const AddButtonContainer = styled.div`
  background-color: black;
  border-radius: 32px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 0.75rem;
  cursor: pointer;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin: 1rem;
`

const MultipleEventPicker: React.FC<Props> = ({
  events,
  onChange,
  onAdd,
  onEditPriority,
  maximumEvents,
  title,
  onClick,
}) => {
  const onRemove = (id: string) => {
    const newValues = events.filter((v) => v.id !== id)
    onChange(newValues)
  }

  return (
    <Container>
      <Title>{title}</Title>
      {events.map((d, i) => (
        <EventContainer key={d.id}>
          {onEditPriority ? (
            <IconContainer onClick={() => onEditPriority!(d)}>
              <BiArrowToTop color="#000000" />
            </IconContainer>
          ) : null}
          <IconContainer onClick={() => onClick(d)}>
            <ImLink color="#000000" />
          </IconContainer>
          {d.imageUrl ? (
            <div style={{ margin: "0 0.5rem" }}>
              <img
                src={d.imageUrl}
                style={{ width: 30, height: 30, objectFit: "cover" }}
              />
            </div>
          ) : null}
          <EventItem>{d.text}</EventItem>
          <RemoveButton onClick={() => onRemove(d.id)}>
            <ImCross color="#ffffff" />
          </RemoveButton>
        </EventContainer>
      ))}
      {events.length < maximumEvents ? (
        <AddButtonContainer onClick={onAdd}>
          <BiAward size={18} />
          <span style={{ marginLeft: 12 }}>Добавить событие</span>
        </AddButtonContainer>
      ) : null}
    </Container>
  )
}

export default MultipleEventPicker
