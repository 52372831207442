import React, { useContext, useEffect, useRef, useState } from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import EventsApi from "../api/EventsApi"
import SearchApi from "../api/SearchApi"
import EntityList from "../components/EntityList"
import { Flex } from "../components/Flex"
import OrganizationEvent from "../components/OrganizationEvent"
import SearchInput from "../components/SearchInput"
import AppContext from "../store/AppContext"
import { toastError } from "../toast"
import {
  EntityItem,
  ModerationFlag,
  OrganizationEventData,
} from "../types/entity"
import { getCityNameByIata } from "../utils"

const Container = styled.div`
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-wrap: wrap;
`
const Button = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? "#fff" : "#000")};
  background-color: ${(props) => (props.active ? "#000" : "#EDEDED")};
  border-radius: 70px;
  cursor: pointer;
  padding: 10px 16px;
  margin: 8px 4px 4px 4px;
  flex-basis: 70px;
`

export default React.memo(function OrganizationEvents() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [cursor, setCursor] = useState<string>()
  const [isLast, setIsLast] = useState<boolean>(true)
  const [items, setItems] = useState<EntityItem[]>([])
  const { cities, admin } = useContext(AppContext)

  const adminCity = cities.filter((c) => admin.cities.includes(c.iata))[0]?.id

  const [selectedCity, setSelectedCity] = useState<string | undefined>(
    admin.permissions.includes("all")
      ? cities[0]?.id || undefined
      : adminCity || undefined
  )

  const loadElements = async (
    page: number,
    emptyItems = false,
    pageCursor?: string
  ) => {
    try {
      const response = await EventsApi.getOrganizationEvents(
        pageCursor,
        false,
        selectedCity
      )
      setCursor(response.data.next_cursor)
      setIsLast(response.data.is_last)
      if (emptyItems) {
        setItems([...mapToEntityList(response.data.results as any)])
      } else {
        setItems([...items, ...mapToEntityList(response.data.results as any)])
      }

      if (emptyItems && response.data.results.length === 0) {
        toastError(undefined, "Нет событий для города")
        console.log("No events")
      }
    } catch (e) {
      toastError(e)
    }
  }

  const searchElements = async (value: string) => {
    try {
      const data = await SearchApi.searchOrganizationEvents(value)
      setCursor(undefined)
      setIsLast(true)
      setItems(mapToEntityList(data.data.results))
    } catch (e) {
      toastError(e)
    }
  }

  const mapToEntityList = (items: OrganizationEventData[]) => {
    return items.map((item) => {
      return {
        id: item.id,
        moderationFlag: item.deleted_at
          ? ModerationFlag.BLOCKED
          : ModerationFlag.OK,
        text: item.title,
      }
    })
  }

  const buttons = cities
    .filter((v) => v.is_available)
    .filter(
      (v) => admin.cities.includes(v.iata) || admin.permissions.includes("all")
    )
    .map((v) => ({
      value: v.id,
      label: getCityNameByIata(v.iata),
    }))

  useEffect(() => {
    setCursor(undefined)
    setItems([])
    setIsLast(false)
    loadElements(0, true, undefined)
    console.log("Selected city changed")
    history.replace(path)
  }, [selectedCity])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div>
        <Buttons>
          {buttons.map((btn, index) => {
            return (
              <Button
                onClick={() => setSelectedCity(btn.value)}
                active={btn.value === selectedCity}
                key={index}
              >
                {btn.label}
              </Button>
            )
          })}
        </Buttons>
      </div>
      <div style={{ display: "flex", overflowY: "auto" }}>
        <Flex direction="column">
          <SearchInput
            initialLoad={() => loadElements(0)}
            query={searchElements}
          />
          <EntityList
            isLast={isLast}
            loadMore={(p) => loadElements(p, false, cursor)}
            items={items}
            onSelect={(item) => {
              console.log(`Item is`, item)
              history.push(`${path}/${item.id}`)
            }}
          />
        </Flex>
        <Container>
          <Switch>
            <Route exact path={path}>
              <h1 style={{ textAlign: "center" }}>
                Она-а-а-а-а плавает в формалине, несовершенство линий...
              </h1>
            </Route>
            <Route path={`${path}/:id`}>
              <OrganizationEvent />
            </Route>
          </Switch>
        </Container>
      </div>
    </div>
  )
})
