export const getToken = () => {
  return localStorage.getItem("token")
}

export const setToken = (token: string) => {
  return localStorage.setItem("token", token)
}

export const getSocketToken = () => {
  return localStorage.getItem("socketToken")
}

export const putHeaders = () => {
  const token = getToken()
  return {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  }
}
