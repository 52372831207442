import React, { useEffect, useState } from "react"
import styled from "styled-components"

type Props = {
  hint: string
  value?: string
  height?: string
  width?: string
  maxWidth?: string
  marginBottom?: string

  onChange: (value: string, remove: boolean) => void
}

const TextAreaContainer = styled.textarea`
  outline: none;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 24px;
  border: 2px solid #ededed;
  border-radius: 30px;
  background: transparent;
  font-size: 20px;
  color: black;
  resize: none;
  font-family: "Open Sans", sans-serif;
  :placeholder {
    color: #979797;
  }
`

const InputContainer = styled.input`
  outline: none;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 24px;
  border: 2px solid #ededed;
  border-radius: 30px;
  background: transparent;
  font-size: 20px;
  color: black;
  resize: none;
  font-family: "Open Sans", sans-serif;
  :placeholder {
    color: #979797;
  }
`

export default function Input(props: Props) {
  let [value, setValue] = useState<string>("")

  useEffect(() => {
    setValue(props.value ? props.value : "")
  }, [props.value])

  function onInputChange(e: any) {
    props.onChange(e.target.value, e.target.value.length < value.length)
    setValue(e.target.value)
  }

  return (
    <React.Fragment>
      {props.height ? (
        <TextAreaContainer
          style={{
            width: props.width,
            maxWidth: props.maxWidth,
            height: props.height ? props.height : "none",
            marginBottom: props.marginBottom,
          }}
          placeholder={props.hint}
          value={value}
          onChange={(e) => {
            onInputChange(e)
          }}
        />
      ) : (
        <InputContainer
          style={{
            width: props.width,
            maxWidth: props.maxWidth,
            marginBottom: props.marginBottom,
          }}
          placeholder={props.hint}
          value={value}
          onChange={(e) => {
            onInputChange(e)
          }}
        />
      )}
    </React.Fragment>
  )
}
