import { AxiosResponse } from "axios"
import {
  CustomEventData,
  OrganizationData,
  OrganizationEventData,
  UserData,
} from "../types/entity"
import axios from "./axios"
import { putHeaders } from "./storage"

export default class SearchApi {
  static async searchOrganizations(
    search: string
  ): Promise<AxiosResponse<{ results: OrganizationData[] }>> {
    try {
      const response = await axios.get(`/admin/organizations/search`, {
        params: {
          search,
        },
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot search organizations`, e)
      throw e
    }
  }

  static async searchOrganizationEvents(
    search: string
  ): Promise<AxiosResponse<{ results: OrganizationEventData[] }>> {
    try {
      const response = await axios.get(`/admin/organization_events/search`, {
        params: {
          search,
        },
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot search organizations`, e)
      throw e
    }
  }

  static async searchCustomEvents(
    search: string
  ): Promise<AxiosResponse<{ results: CustomEventData[] }>> {
    try {
      const response = await axios.get(`/admin/custom_events/search`, {
        params: {
          search,
        },
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot search organizations`, e)
      throw e
    }
  }

  static async searchUsers(
    search: string
  ): Promise<AxiosResponse<{ results: UserData[] }>> {
    try {
      const response = await axios.get(`/admin/users/search`, {
        params: {
          search,
        },
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot search organizations`, e)
      throw e
    }
  }
}
