import styled from "styled-components"
import { OrganizationEventReport } from "../../types/entity"
import MyImage from "../MyImage"
import { Flex } from "../Flex"
import { Section } from "../../types/section"
import ReportedText from "./ReportedText"

const ImageWrapper = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 14px;
`

const DescriptionInfo = styled.div`
  margin-top: 20px;
  font-weight: 600;
  font-size: 20px;
  text-align: left;

  a {
    text-decoration: none;
    color: #000;
    max-width: 170px;
    word-break: break-word;
  }
`

const OrganizationEventInfo: React.FC<{
  report: OrganizationEventReport
}> = ({ report }) => {
  return (
    <Flex align="center">
      <Flex direction="column">
        <Flex align="center">
          <a
            href={`${Section.ORGANIZATION_EVENTS}/${report.reported_event_id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ImageWrapper>
              <MyImage
                imgSrc={report.reported_event.media[0]?.url || undefined}
                readOnly
                onUpload={() => {}}
                imgTitle={"Organization Place"}
              />
            </ImageWrapper>
          </a>
          <DescriptionInfo>
            <a
              href={`${Section.ORGANIZATION_EVENTS}/${report.reported_event_id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {report.reported_event.title}
            </a>
          </DescriptionInfo>
        </Flex>

        <Flex direction="column">
          <DescriptionInfo>Причина: {report.report_reason}</DescriptionInfo>

          {report.text && <ReportedText text={report.text} />}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default OrganizationEventInfo
