import { UserData } from "../../types/entity"
import styled from "styled-components"
import MyImage from "../MyImage"
import { Flex } from "../Flex"
import { Section } from "../../types/section"

const ImageWrapper = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 10px;
`

const Text = styled.div<{ bold?: boolean }>`
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? "600" : "600")};
  color: ${(props) => (props.bold ? "#000" : "#bcbcbc")};
  width: 180px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 3px;
  a {
    text-decoration: none;
    color: #000;
  }
`
const UserInfo: React.FC<{ user: UserData }> = ({ user }) => {
  return (
    <Flex>
      <a
        href={`${Section.USERS}/${user.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <ImageWrapper>
          <MyImage
            imgSrc={user.avatar_url || undefined}
            readOnly
            onUpload={() => {}}
            imgTitle={user.first_name || user.phone || "User"}
          />
        </ImageWrapper>
      </a>
      <Flex direction="column" align="flex-start">
        <Text bold>
          {" "}
          <a
            href={`${Section.USERS}/${user.id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {user.first_name || user.phone || "User"}{" "}
          </a>
        </Text>
        <Text title={user.description || "No description"}>
          {user.description || "No description"}
        </Text>
        <Text title={user.job || "No job"}>{user.job || "No job"}</Text>
      </Flex>
    </Flex>
  )
}

export default UserInfo
