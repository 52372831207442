import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"

type TextAreaProps = InputHTMLAttributes<HTMLTextAreaElement> & {}

const TextAreaContainer = styled.textarea`
  outline: none;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 14px 24px;
  border: 2px solid #ededed;
  border-radius: 30px;
  background: transparent;
  font-size: 20px;
  color: black;
  resize: none;
  font-family: "Open Sans", sans-serif;
  :placeholder {
    color: #979797;
  }
`
const TextArea: React.FC<TextAreaProps> = ({ ...props }) => {
  return <TextAreaContainer {...props} />
}

export default TextArea
