import { AxiosResponse } from "axios"
import { City } from "../types/entity"
import axios from "./axios"
import { putHeaders } from "./storage"

export default class CityApi {
  static async getCities(): Promise<
    AxiosResponse<{
      results: City[]
    }>
  > {
    try {
      const response = await axios.get("/admin/cities", {
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot get cities`, e)
      throw e
    }
  }
}
