import React, { useEffect, useState } from "react"
import styled from "styled-components"
import ActionModal from "./ActionModal"
import { Flex } from "./Flex"
import Input from "./MyInput"

type Props = {
  onChange: (val: any) => void
  onClose: () => void
  onSubmit: (val: any) => void
  type: "number" | "text"
  title: string
}

const ModalButton = styled.span`
  margin: 0 50px;
  font-size: 24px;
  cursor: pointer;
`

export default function EditValueWindow(props: Props) {
  const [val, setVal] = useState<any>("")

  useEffect(() => {
    props.onChange(val)
  }, [val])

  return (
    <ActionModal onClose={props.onClose} title={props.title}>
      <Input
        type={props.type}
        value={val}
        onChange={(e) => {
          setVal(e.target.value)
        }}
      />
      <Flex justify="center" mt="30px">
        <ModalButton onClick={props.onClose}>Закрыть</ModalButton>
        <ModalButton onClick={() => props.onSubmit(val)}>
          Установить
        </ModalButton>
      </Flex>
    </ActionModal>
  )
}
