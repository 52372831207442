import styled from "styled-components"

type props = {
  justify?: string
  align?: string
  direction?: string
  mb?: string
  mt?: string
  mx?: string
  basis?: string
  width?: string
}

export const Flex = styled.div<props>`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "none")};
  align-items: ${(props) => (props.align ? props.align : "none")};
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0")};
  margin-top: ${(props) => (props.mt ? props.mt : "0")};
  margin-left: ${(props) => (props.mx ? props.mx : "0")};
  margin-right: ${(props) => (props.mx ? props.mx : "0")};
  flex-basis: ${(props) => (props.basis ? props.basis : "initial")};
  width: ${(props) => (props.width ? props.width : "auto")};
`
