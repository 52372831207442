import { putHeaders } from "./storage"
import axios from "./axios"
import { AxiosResponse } from "axios"
import { ScreenStats } from "../types/entity"

type SimpleData = {
  users_contacts_synced: number
  positive_feedbacks: number
}

export default class StatsApi {
  static async getSimpleData(): Promise<AxiosResponse<SimpleData>> {
    try {
      const response = await axios.get(`/admin/graphs/simple`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get simple stats`, e)
      throw e
    }
  }

  static async getScreenStats(): Promise<AxiosResponse<ScreenStats>> {
    try {
      const response = await axios.get(`/admin/stats`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get stats`, e)
      throw e
    }
  }

  static async getActiveUsersByWeek(): Promise<AxiosResponse<{ chart: any }>> {
    try {
      const response = await axios.get(`/admin/graphs/active_users`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get active users`, e)
      throw e
    }
  }

  static async getUsersIncomeByWeek(): Promise<AxiosResponse<{ chart: any }>> {
    try {
      const response = await axios.get(`/admin/graphs/users_income`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get users income`, e)
      throw e
    }
  }

  static async getUsersActivityByWeek(): Promise<
    AxiosResponse<{ chart: any }>
  > {
    try {
      const response = await axios.get(`/admin/graphs/users_activity`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get users activity`, e)
      throw e
    }
  }
}
