import React, { useEffect, useRef, useState } from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import EventsApi from "../api/EventsApi"
import SearchApi from "../api/SearchApi"
import EntityList from "../components/EntityList"
import { Flex } from "../components/Flex"
import OrganizationEvent from "../components/OrganizationEvent"
import SearchInput from "../components/SearchInput"
import { toastError } from "../toast"
import {
  EntityItem,
  ModerationFlag,
  OrganizationEventData,
} from "../types/entity"

const Container = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

export default React.memo(function PriorityEvents() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [cursor, setCursor] = useState<string>()
  const [isLast, setIsLast] = useState<boolean>(true)
  const [items, setItems] = useState<EntityItem[]>([])
  const mounted = useRef(true)

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const loadElements = async () => {
    try {
      const response = await EventsApi.getOrganizationEvents(cursor, true)
      setCursor(response.data.next_cursor)
      setIsLast(response.data.is_last)
      setItems([...items, ...mapToEntityList(response.data.results as any)])
    } catch (e) {
      toastError(e)
    }
  }

  const initialLoad = async () => {
    try {
      const response = await EventsApi.getOrganizationEvents(cursor, true)
      if (mounted.current) {
        setCursor(response.data.next_cursor)
        setIsLast(response.data.is_last)
        setItems(mapToEntityList(response.data.results as any))
        console.log(`Set cursor to ${response.data.next_cursor}`)
      } else {
        console.log(`No mounted`)
      }
    } catch (e) {
      toastError(e)
    }
  }

  const searchElements = async (value: string) => {
    try {
      const data = await SearchApi.searchOrganizationEvents(value)
      setCursor(undefined)
      setIsLast(true)
      setItems(mapToEntityList(data.data.results))
    } catch (e) {
      toastError(e)
    }
  }

  const mapToEntityList = (items: OrganizationEventData[]) => {
    return items.map((item) => {
      const priority = `[${item.priority}]`
      return {
        id: item.id,
        moderationFlag: item.deleted_at
          ? ModerationFlag.BLOCKED
          : ModerationFlag.OK,
        text: `${priority} ${item.title}`,
      }
    })
  }

  return (
    <React.Fragment>
      <Flex direction="column">
        <SearchInput initialLoad={initialLoad} query={searchElements} />
        <EntityList
          isLast={isLast}
          loadMore={loadElements}
          items={items}
          onSelect={(item) => {
            console.log(`Item is`, item)
            history.push(`${path}/${item.id}`)
          }}
        />
      </Flex>
      <Container>
        <Switch>
          <Route exact path={path}>
            <h1 style={{ textAlign: "center" }}>
              Она-а-а-а-а плавает в формалине, несовершенство линий...
            </h1>
          </Route>
          <Route path={`${path}/:id`}>
            <OrganizationEvent withPriority />
          </Route>
        </Switch>
      </Container>
    </React.Fragment>
  )
})
