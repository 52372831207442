import { AxiosResponse } from "axios"
import {
  CustomEventReport,
  MeetingReport,
  OrganizationEventReport,
  OrganizationReport,
  UserData,
  UserReport,
} from "../types/entity"
import axios from "./axios"
import { putHeaders } from "./storage"

export default class ReportsApi {
  static async getMeetings(cursor?: string): Promise<
    AxiosResponse<{
      results: MeetingReport[]
      cursor: string
      is_last: boolean
    }>
  > {
    try {
      const response = await axios.get("/admin/meetings", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot get reports`, e)
      throw e
    }
  }

  static async getDestroyReports(cursor?: string): Promise<
    AxiosResponse<{
      results: UserData[]
      cursor: string
      is_last: boolean
    }>
  > {
    try {
      const response = await axios.get("/admin/users/destroyed/reasoned", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error("Cannot get Destroy reasons", e)
      throw e
    }
  }

  static async getUsersReports(cursor?: string): Promise<
    AxiosResponse<{
      results: UserReport[]
      cursor: string
      is_last: boolean
    }>
  > {
    try {
      const response = await axios.get("/admin/reports/users", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error("Cannot users reports", e)
      throw e
    }
  }

  static async getCustomEventsReports(cursor?: string): Promise<
    AxiosResponse<{
      results: CustomEventReport[]
      cursor: string
      is_last: boolean
    }>
  > {
    try {
      const response = await axios.get("/admin/reports/custom_events", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error("Cannot get custom events reports", e)
      throw e
    }
  }
  static async getOrganizationEventsReports(cursor?: string): Promise<
    AxiosResponse<{
      results: OrganizationEventReport[]
      cursor: string
      is_last: boolean
    }>
  > {
    try {
      const response = await axios.get("/admin/reports/organization_events", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error("Cannot get organization events reports", e)
      throw e
    }
  }

  static async getOrganizationReports(cursor?: string): Promise<
    AxiosResponse<{
      results: OrganizationReport[]
      cursor: string
      is_last: boolean
    }>
  > {
    try {
      const response = await axios.get("/admin/reports/organizations", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error("Cannot get organization events reports", e)
      throw e
    }
  }
}
