import React, { useContext, useEffect } from "react"
import { Route, Redirect, useHistory } from "react-router-dom"
import AppContext from "../../store/AppContext"

export default function PrivateRoute({ children, ...rest }: any) {
  const { authenticated, checking } = useContext(AppContext)
  const history = useHistory()
  useEffect(() => {
    if (!authenticated) {
      history.replace("/login")
    }
  }, [authenticated])
  if (checking) {
    return <Route {...rest} render={() => <div>Loading...</div>} />
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
