export enum Section {
  ORGANIZATION_EVENTS = "/organization_events",
  CUSTOM_EVENTS = "/custom_events",
  ORGANIZATIONS = "/organizations",
  FEEDBACKS = "/feedbacks",
  CHATS = "/chats",
  USERS = "/users",
  LOGIN = "/login",
  COUNSELORS = "/counselors",
  UNCHECKED_USERS = "/unchecked",
  SCREEN_STATS = "/stats",
  GRAPHS = "/graphs",
  PRIORITY_EVENTS = "/priority_events",
}
