import React, { useState } from "react"
import styled from "styled-components"
import Report from "../components/  Report"
import FeedBackEntity from "../components/FeedbackEntity"
import { Flex } from "../components/Flex"
import { FeedbackType, ReportType } from "../types/entity"

const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-basis: 20%;
  flex-wrap: wrap;
`
const Button = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? "#fff" : "#000")};
  background-color: ${(props) => (props.active ? "#000" : "#EDEDED")};
  border-radius: 70px;
  cursor: pointer;
  padding: 10px 16px;
  margin: 8px 4px 4px 4px;
`

const buttons = [
  {
    value: FeedbackType.MEETING,
    label: "Встречи",
  },
  {
    value: FeedbackType.DELETE,
    label: "Удаление",
  },
  {
    value: FeedbackType.EVENTS,
    label: "События",
  },
  {
    value: FeedbackType.ORGANIZATIONS,
    label: "Организации",
  },
  {
    value: FeedbackType.CUSTOM_EVENTS,
    label: "События людей",
  },
  {
    value: FeedbackType.USERS,
    label: "Пользователи",
  },
]

const Container = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

const FeedBacks: React.FC = () => {
  const [button, setButton] = useState<FeedbackType>(FeedbackType.MEETING)
  const [item, setItem] = useState<ReportType | null>(null)

  const changeButton = (value: FeedbackType) => {
    setItem(null)
    setButton(value)
  }

  return (
    <>
      <Flex justify="flex-start" width="360px" direction="column">
        <Buttons>
          {buttons.map((btn, index) => {
            return (
              <Button
                onClick={() => changeButton(btn.value)}
                active={btn.value === button}
                key={index}
              >
                {btn.label}
              </Button>
            )
          })}
        </Buttons>
        <FeedBackEntity
          selectedItem={item}
          onSelect={(item) => setItem(item)}
          type={button}
        />
      </Flex>
      <Container>{item && <Report type={button} report={item} />}</Container>
    </>
  )
}

export default FeedBacks
