import { useEffect, useState } from "react"
import "react-day-picker/lib/style.css"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import styled from "styled-components"
import AuthApi from "./api/AuthApi"
import CityApi from "./api/CityApi"
import Organization from "./components/Organization"
import OrganizationEvent from "./components/OrganizationEvent"
import PrivateRoute from "./components/router/PrivateRoute"
import Sections from "./components/Sections"
import AuthCheck from "./pages/AuthCheck"
import Chats from "./pages/Chats"
import CustomEvents from "./pages/CustomEvents"
import FeedBacks from "./pages/Feedbacks"
import Login from "./pages/Login"
import OrganizationEvents from "./pages/OrganizationEvents"
import Organizations from "./pages/Organizations"
import ScreenPage from "./pages/ScreenPage"
import Users from "./pages/Users"
import AppContext from "./store/AppContext"
import { toastError } from "./toast"
import { Admin, City } from "./types/entity"
import Counselors from "./pages/Counselors"
import CreateCounselor from "./components/CreateCounselor"
import UncheckedUsers from "./pages/UncheckedUsers"
import PriorityEvents from "./pages/PriorityEvents"
import Graphs from "./pages/Graphs"

const AppContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  background: #f8f8f8;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
`

function App() {
  const [chooseWindow, setChooseWindow] = useState<JSX.Element | null>(null)
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [checking, setChecking] = useState<boolean>(true)
  const [cities, setCities] = useState<City[]>([])
  const [admin, setAdmin] = useState<Admin>({
    email: "",
    name: "",
    permissions: [],
    cities: [],
  })

  const getCities = async () => {
    try {
      const response = await CityApi.getCities()
      setCities(response.data.results)
    } catch (e) {
      setCities([])
      toastError(e)
    }
  }

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setChecking(true)
        const response = await AuthApi.check()
        setAdmin({
          ...response.data,
          cities: response.data.cities || [],
        })
        await getCities()
        setAuthenticated(true)
      } catch (e) {
        setAuthenticated(false)
      } finally {
        setChecking(false)
      }
    }
    checkAuth()
  }, [])

  return (
    <AppContainer>
      <AppContext.Provider
        value={{
          authenticated,
          setAuthenticated,
          chooseWindow,
          setChooseWindow,
          checking,
          setChecking,
          cities,
          setCities,
          admin,
          setAdmin,
        }}
      >
        {chooseWindow}
        {checking ? (
          <div>
            <h1>Загружаю-выгружаю...</h1>
          </div>
        ) : (
          <Router>
            <Sections />
            <Switch>
              <PrivateRoute exact path="/organizations/create">
                <Organization />
              </PrivateRoute>
              <PrivateRoute path="/organizations">
                <Organizations />
              </PrivateRoute>
              <PrivateRoute path="/users">
                <Users />
              </PrivateRoute>
              <PrivateRoute path="/unchecked">
                <UncheckedUsers />
              </PrivateRoute>
              <PrivateRoute path="/custom_events">
                <CustomEvents />
              </PrivateRoute>
              <PrivateRoute exact path="/organization_events/create">
                <OrganizationEvent />
              </PrivateRoute>
              <PrivateRoute path="/organization_events">
                <OrganizationEvents />
              </PrivateRoute>
              <PrivateRoute path="/priority_events">
                <PriorityEvents />
              </PrivateRoute>
              <PrivateRoute path="/chats">
                <Chats />
              </PrivateRoute>
              <PrivateRoute path="/feedbacks">
                <FeedBacks />
              </PrivateRoute>
              <PrivateRoute path="/counselors/create">
                <CreateCounselor />
              </PrivateRoute>
              <PrivateRoute path="/counselors">
                <Counselors />
              </PrivateRoute>
              <PrivateRoute path="/graphs">
                <Graphs />
              </PrivateRoute>
              <Route path="/login">
                <Login />
              </Route>
              <Route exact path="/">
                <AuthCheck extractToken={false} />
              </Route>
              <Route path="/auth/success">
                <AuthCheck extractToken />
              </Route>
              <Route path="/stats">
                <ScreenPage />
              </Route>
            </Switch>
          </Router>
        )}
      </AppContext.Provider>
    </AppContainer>
  )
}

export default App
