import React, { useState, useEffect, useContext } from "react"
import AppContext from "../store/AppContext"
import { useParams } from "react-router"
import styled from "styled-components"
import Checkbox from "./Checkbox"
import Button from "./Button"
import { Flex } from "./Flex"
import Input from "./MyInput"
import TextArea from "./MyTextArea"
import MyImage from "./MyImage"
import OrganizationApi from "../api/OrganizationApi"
import { OrganizationData } from "../types/entity"
import ActionModal from "./ActionModal"
import { OrganizationType } from "../types/form"
import { toastError } from "../toast"
import { useHistory } from "react-router-dom"

const InputContainer = styled.div`
  width: 400px;
  height: 60px;
  margin-bottom: 22px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const TextAreaContainer = styled.div`
  width: 400px;
  height: 160px;
  margin-bottom: 22px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const CheckBoxContainer = styled.div`
  margin-bottom: 40px;
  margin-left: 46px;
`

const ImageContainerLarge = styled.div`
  width: 300px;
  height: 150px;
  border-radius: 24px;
  margin-bottom: 30px;
  @media (max-width: 1400px) {
    width: 200px;
    height: 100px;
  }
  @media (max-width: 1200px) {
    width: 150px;
    height: 75px;
  }
`
const ImageContainerSmall = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media (max-width: 1400px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 1200px) {
    width: 75px;
    height: 75px;
  }
`
const ModalButton = styled.span`
  margin: 0 50px;
  font-size: 24px;
  cursor: pointer;
`

const Header = styled.h1`
  margin-left: 30px;
  margin-bottom: 44px;
`

const CreateContainer = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

type OrganizationMediaType = "avatar" | "background"

const Organization: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [formData, setFormData] = useState(
    new Map<OrganizationMediaType, Blob | null>()
  )
  const history = useHistory()
  const [form, setForm] = useState<OrganizationType>({
    name: "",
    verified: false,
    instagram: "",
    facebook: "",
    youtube: "",
    description: "",
    avatar_url: "",
    background_url: "",
    ticket_url: "",
    url: "",
    available: false,
  })
  const [organization, setOrganization] = useState<OrganizationData | null>(
    null
  )

  const { setChooseWindow } = useContext(AppContext)

  const fileHandler = (value: Blob | null, type: OrganizationMediaType) => {
    setForm({
      ...form,
      ...(type === "background"
        ? {
            background_url: value,
          }
        : { avatar_url: value }),
    })
    setFormData(formData.set(type, value))
  }

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (id) {
      const loadOrganization = async (id: string) => {
        try {
          const response = await OrganizationApi.getOrganizationDetails(id)
          initForm(response.data.result)
          console.error(response.data.result)
        } catch (e) {
          console.error(`Cannot get organizations`, e)
          toastError(e)
        }
      }
      loadOrganization(id)
    }
  }, [id])

  const initForm = (data: OrganizationData) => {
    const newForm = {
      name: data.name,
      verified: Boolean(data.verified),
      instagram: !!data.instagram ? data.instagram : "",
      facebook: !!data.facebook ? data.facebook : "",
      youtube: !!data.youtube ? data.youtube : "",
      description: !!data.description ? data.description : "",
      avatar_url: !!data.avatar_url ? data.avatar_url : "",
      background_url: !!data.background_url ? data.background_url : "",
      ticket_url: !!data.ticket_url ? data.ticket_url : "",
      url: !!data.url ? data.url : "",
      available: Boolean(data.available),
    }
    setForm(newForm)
    setOrganization(data)
  }

  const prepareForm = (): Record<string, any> => {
    const deletion: string[] = ["avatar_url", "background_url"]
    return (Object.keys(form) as Array<keyof typeof form>)
      .filter((key) => !deletion.includes(key))
      .reduce<any>((acc, value) => {
        if (form[value] === undefined || form[value] === "") {
          return acc
        }
        acc[value] = form[value]
        return acc
      }, {})
  }

  const onSave = async () => {
    try {
      setDisableSubmit(true)
      const newForm = prepareForm()
      const submitFormData = generateFormData(newForm)
      const response = await OrganizationApi.updateOrganization(
        id!,
        submitFormData
      )
      initForm(response.data.result)
      setDisableSubmit(false)
    } catch (e) {
      toastError(e)
      setDisableSubmit(false)
    }
  }

  const onCreate = async () => {
    try {
      setDisableSubmit(true)
      const newForm = prepareForm()
      const submitFormData = generateFormData(newForm)
      const response = await OrganizationApi.createOrganization(submitFormData)
      history.push(`/organizations/${response.data.result.id}`)
      setDisableSubmit(false)
    } catch (e) {
      toastError(e)
      setDisableSubmit(false)
    }
  }

  const generateFormData = (f: Record<string, any>): FormData => {
    const newFormData = new FormData()
    const avatar = formData.get("avatar")
    const background = formData.get("background")
    if (avatar) {
      console.log(`Adding key avatar`)
      newFormData.append("avatar", avatar)
    }
    if (background) {
      console.log(`Adding key background`)
      newFormData.append("background", background)
    }
    Object.keys(f).forEach((k) => {
      console.log(`Adding key ${k} with value ${f[k]}`)
      newFormData.append(k, f[k])
    })
    return newFormData
  }

  const deleteOrganization = async () => {
    try {
      setDisableSubmit(true)
      setChooseWindow(null)
      const response = await OrganizationApi.deleteOrganization(id!)
      initForm(response.data.result)
    } catch (e) {
      toastError(e)
    } finally {
      setDisableSubmit(false)
    }
  }

  const onDelete = () => {
    const JSX = (
      <ActionModal
        title="Удалить организацию?"
        onClose={() => setChooseWindow(null)}
      >
        <Flex justify="center">
          <ModalButton onClick={() => setChooseWindow(null)} role="button">
            Нет
          </ModalButton>
          <ModalButton onClick={() => deleteOrganization()} role="button">
            Да
          </ModalButton>
        </Flex>
      </ActionModal>
    )
    setChooseWindow(JSX)
  }

  if (!id) {
    return (
      <CreateContainer>
        <Header>Создать организацию</Header>
        <Flex>
          <Flex direction="column">
            <InputContainer>
              <Input
                name="name"
                value={form.name}
                onChange={changeHandler}
                placeholder="Название"
              />
            </InputContainer>

            <InputContainer>
              <Input
                name="url"
                onChange={changeHandler}
                value={form.url}
                placeholder="Ссылка на сайт"
              />
            </InputContainer>

            <InputContainer>
              <Input
                onChange={changeHandler}
                name="ticket_url"
                value={form.ticket_url}
                placeholder="Ссылка на билеты"
              />
            </InputContainer>
            <InputContainer>
              <Input
                name="instagram"
                value={form.instagram}
                onChange={changeHandler}
                placeholder="Инстаграм"
              />
            </InputContainer>
            <InputContainer>
              <Input
                name="facebook"
                value={form.facebook}
                onChange={changeHandler}
                placeholder="Фейсбук"
              />
            </InputContainer>
            <InputContainer>
              <Input
                name="youtube"
                value={form.youtube}
                onChange={changeHandler}
                placeholder="Ютуб"
              />
            </InputContainer>
            <TextAreaContainer>
              <TextArea
                value={form.description}
                name="description"
                onChange={changeHandler}
                placeholder="О нас"
              />
            </TextAreaContainer>
          </Flex>
          <Flex direction="column">
            <CheckBoxContainer>
              <Checkbox
                text="Верификация"
                active={form.verified}
                onChange={(v) => {
                  setForm({ ...form, verified: v })
                }}
              />
            </CheckBoxContainer>
            <CheckBoxContainer>
              <Checkbox
                text="Отображать в приложении"
                active={form.available}
                onChange={(v) => {
                  setForm({ ...form, available: v })
                }}
              />
            </CheckBoxContainer>
            <ImagesContainer>
              <ImageContainerLarge>
                <MyImage
                  imgTitle="Бэкграунд"
                  imgSrc={
                    typeof form.background_url === "string"
                      ? form.background_url
                      : undefined
                  }
                  onUpload={(value) => fileHandler(value, "background")}
                />
              </ImageContainerLarge>
              <ImageContainerSmall>
                <MyImage
                  imgSrc={
                    typeof form.avatar_url === "string"
                      ? form.avatar_url
                      : undefined
                  }
                  onUpload={(value) => fileHandler(value, "avatar")}
                  imgTitle="Аватар"
                />
              </ImageContainerSmall>
            </ImagesContainer>
          </Flex>
        </Flex>
        <Flex mt="20px">
          <Button text="Создать" active={!disableSubmit} onClick={onCreate} />
        </Flex>
      </CreateContainer>
    )
  }

  return (
    <>
      <Flex>
        <Flex direction="column">
          <InputContainer>
            <Input
              name="name"
              value={form.name}
              onChange={changeHandler}
              placeholder="Название"
            />
          </InputContainer>

          <InputContainer>
            <Input
              name="url"
              onChange={changeHandler}
              value={form.url}
              placeholder="Ссылка на сайт"
            />
          </InputContainer>

          <InputContainer>
            <Input
              onChange={changeHandler}
              name="ticket_url"
              value={form.ticket_url}
              placeholder="Ссылка на билеты"
            />
          </InputContainer>
          <InputContainer>
            <Input
              name="instagram"
              value={form.instagram}
              onChange={changeHandler}
              placeholder="Инстаграм"
            />
          </InputContainer>
          <InputContainer>
            <Input
              name="facebook"
              value={form.facebook}
              onChange={changeHandler}
              placeholder="Фейсбук"
            />
          </InputContainer>
          <InputContainer>
            <Input
              name="youtube"
              value={form.youtube}
              onChange={changeHandler}
              placeholder="Ютуб"
            />
          </InputContainer>
          <TextAreaContainer>
            <TextArea
              value={form.description}
              name="description"
              onChange={changeHandler}
              placeholder="О нас"
            />
          </TextAreaContainer>
        </Flex>
        <Flex direction="column">
          <CheckBoxContainer>
            <Checkbox
              text="Верификация"
              active={form.verified}
              onChange={(v) => {
                setForm({ ...form, verified: v })
              }}
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              text="Отображать в приложении"
              active={form.available}
              onChange={(v) => {
                setForm({ ...form, available: v })
              }}
            />
          </CheckBoxContainer>
          <ImagesContainer>
            <ImageContainerLarge>
              <MyImage
                imgTitle="Бэкграунд"
                imgSrc={
                  typeof form.background_url === "string"
                    ? form.background_url
                    : undefined
                }
                onUpload={(value) => fileHandler(value, "background")}
              />
            </ImageContainerLarge>
            <ImageContainerSmall>
              <MyImage
                imgSrc={
                  typeof form.avatar_url === "string"
                    ? form.avatar_url
                    : undefined
                }
                onUpload={(value) => fileHandler(value, "avatar")}
                imgTitle="Аватар"
              />
            </ImageContainerSmall>
          </ImagesContainer>
        </Flex>
      </Flex>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button text="Сохранить" active={!disableSubmit} onClick={onSave} />
        {organization?.deleted_at ? (
          <Button text="Организация удалена" onClick={() => {}} />
        ) : (
          <Button active={!disableSubmit} text="Удалить" onClick={onDelete} />
        )}
      </div>
    </>
  )
}

export default Organization
