import { putHeaders } from "./storage"
import axios from "./axios"
import { AxiosResponse } from "axios"
import { OrganizationData } from "../types/entity"

export default class OrganizationApi {
  static async getOrganizationDetails(
    id: string
  ): Promise<AxiosResponse<{ result: OrganizationData }>> {
    try {
      const response = await axios.get(`/admin/organizations/details/${id}`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get users`, e)
      throw e
    }
  }

  static async getOrganizations(cursor?: string) {
    try {
      const response = await axios.get("/admin/list/organizations", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })

      return response.data
    } catch (e) {
      console.error(`Cannot get organizations`, e)
      throw e
    }
  }

  static async createOrganization(
    data: FormData
  ): Promise<AxiosResponse<{ result: OrganizationData }>> {
    try {
      const response = await axios.post("/admin/organizations/create", data, {
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot create organization`, e)
      throw e
    }
  }

  static async updateOrganization(
    id: string,
    data: FormData
  ): Promise<AxiosResponse<{ result: OrganizationData }>> {
    try {
      const response = await axios.post(
        `/admin/organizations/update/${id}`,
        data,
        {
          headers: putHeaders(),
        }
      )

      return response
    } catch (e) {
      console.error(`Cannot update organization`, e)
      throw e
    }
  }

  static async deleteOrganization(
    id: string
  ): Promise<AxiosResponse<{ result: OrganizationData }>> {
    try {
      const response = await axios.post(
        `/admin/organizations/remove/${id}`,
        {},
        {
          headers: putHeaders(),
        }
      )

      return response
    } catch (e) {
      console.error(`Cannot delete organization`, e)
      throw e
    }
  }
}
