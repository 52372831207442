import React, { useContext, useState } from "react"
import styled from "styled-components"
import UserApi from "../../api/UserApi"
import AppContext from "../../store/AppContext"
import ActionModal from "../ActionModal"
import Button from "../Button"
import { Flex } from "../Flex"
import MyTextArea from "../MyTextArea"

const TextAreaWrapper = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
`
const ModalButton = styled.span`
  margin: 0 50px;
  font-size: 24px;
  cursor: pointer;
`

const Modal: React.FC<{
  onClose(): void
  onYes(reason: string, until: string): void
}> = ({ onClose, onYes }) => {
  const [reason, setReason] = useState("")
  const [until, _setUntil] = useState<Date | undefined>(new Date("10.10.2099"))

  return (
    <ActionModal onClose={onClose} title="Заблокировать пользователя?">
      <TextAreaWrapper>
        <MyTextArea
          value={reason}
          placeholder="Причина"
          onChange={(e) => setReason(e.target.value)}
        />
      </TextAreaWrapper>
      {/* <StyledDatePicker>
        <DayPickerInput
          value={until}
          onDayChange={(date) => {
            setUntil(date)
          }}
          format="dd.MM.YYYY"
          placeholder={"Заблокировать до"}
          inputProps={{ readOnly: true }}
        />
      </StyledDatePicker> */}
      <Flex justify="center" mt="30px">
        <ModalButton onClick={onClose}>Закрыть</ModalButton>
        <ModalButton
          onClick={() => {
            if (!until || !reason) return
            onYes(reason, until.toISOString())
          }}
        >
          Выполнить
        </ModalButton>
      </Flex>
    </ActionModal>
  )
}

const BlockButton: React.FC<{ id: string; active: boolean; reload(): void }> =
  ({ id, active, reload }) => {
    const { setChooseWindow } = useContext(AppContext)
    const blockRequest = async (reason: string, blocked_until: string) => {
      try {
        await UserApi.blockUser(id, { reason, blocked_until })
        reload()
      } catch (e) {
        console.log(e)
      } finally {
        setChooseWindow(null)
      }
    }

    const blockUser = () => {
      setChooseWindow(
        <Modal onClose={() => setChooseWindow(null)} onYes={blockRequest} />
      )
    }

    return <Button active={active} onClick={blockUser} text="Блокировка" />
  }

export default BlockButton
