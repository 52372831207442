import React, { useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import AppContext from "../store/AppContext"
import { AdminPermission } from "../types/entity"
import { Section } from "../types/section"
import ActionModal from "./ActionModal"
import { Flex } from "./Flex"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 90px;
  padding: 30px 0 30px 20px;
`

const Button = styled.div<{ active: boolean }>`
  white-space: nowrap;
  font-size: 25px;
  color: ${({ active }) => (active ? "#000000" : "#979797")};
  cursor: pointer;
`

const Logo = styled.img`
  width: 92px;
  height: 45px;
  cursor: pointer;
`

const Add = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
`
const CreateItem = styled.div`
  color: #979797;
  font-size: 30px;
  cursor: pointer;
  margin-bottom: 24px;
  transition: 0.3s ease-in-out;
  :hover {
    color: #000;
  }
`

export default function Sections() {
  const history = useHistory()
  const location = useLocation()
  const { authenticated, setChooseWindow, admin } = useContext(AppContext)

  const onChangeRoute = (route: Section) => {
    history.push(route)
  }

  const onAdd = () => {
    const JSX = (
      <ActionModal
        width={"380px"}
        onClose={() => setChooseWindow(null)}
        title="Создать"
      >
        <Flex justify="center" align="center" direction="column">
          {checkPermission("modify_organization_events") ? (
            <CreateItem
              onClick={() => {
                history.push("/organization_events/create")
                setChooseWindow(null)
              }}
            >
              Событие
            </CreateItem>
          ) : null}
          {checkPermission("modify_organizations") ? (
            <CreateItem
              onClick={() => {
                history.push("/organizations/create")
                setChooseWindow(null)
              }}
            >
              Организацию
            </CreateItem>
          ) : null}
          {checkPermission("modify_counselors") ? (
            <CreateItem
              onClick={() => {
                history.push("/counselors/create")
                setChooseWindow(null)
              }}
            >
              Рекомендацию
            </CreateItem>
          ) : null}
        </Flex>
      </ActionModal>
    )
    setChooseWindow(JSX)
  }

  const checkPermission = (permission: AdminPermission) => {
    const perms = admin.permissions
    if (perms.includes(permission) || perms.includes("all")) {
      return true
    }
    return false
  }

  if (location.pathname.includes("/stats")) {
    return null
  }

  return (
    <Container>
      <div>
        <Logo
          onClick={() => history.push("/")}
          src={`${process.env.PUBLIC_URL}/img/logo.svg`}
          alt="APT"
        />
        <div>Привет, {admin.name}</div>
      </div>

      {authenticated ? (
        <React.Fragment>
          <div>
            {checkPermission("organization_events") ? (
              <Button
                active={location.pathname.startsWith(
                  Section.ORGANIZATION_EVENTS
                )}
                onClick={() => onChangeRoute(Section.ORGANIZATION_EVENTS)}
              >
                События
              </Button>
            ) : null}
            {checkPermission("organizations") ? (
              <Button
                active={location.pathname.startsWith(Section.ORGANIZATIONS)}
                onClick={() => onChangeRoute(Section.ORGANIZATIONS)}
              >
                Организации
              </Button>
            ) : null}
            {checkPermission("users") ? (
              <Button
                active={location.pathname.startsWith(Section.USERS)}
                onClick={() => onChangeRoute(Section.USERS)}
              >
                Пользователи
              </Button>
            ) : null}
            {checkPermission("custom_events") ? (
              <Button
                active={location.pathname.startsWith(Section.CUSTOM_EVENTS)}
                onClick={() => onChangeRoute(Section.CUSTOM_EVENTS)}
              >
                Свои события
              </Button>
            ) : null}
            {checkPermission("support_chats") ? (
              <Button
                active={location.pathname.startsWith(Section.CHATS)}
                onClick={() => onChangeRoute(Section.CHATS)}
              >
                Чаты
              </Button>
            ) : null}
            {checkPermission("reports") ||
            checkPermission("meeting_feedbacks") ? (
              <Button
                active={location.pathname.startsWith(Section.FEEDBACKS)}
                onClick={() => onChangeRoute(Section.FEEDBACKS)}
              >
                Фидбеки
              </Button>
            ) : null}
            {checkPermission("counselors") ? (
              <Button
                active={location.pathname.startsWith(Section.COUNSELORS)}
                onClick={() => onChangeRoute(Section.COUNSELORS)}
              >
                Рекомендации
              </Button>
            ) : null}
            {checkPermission("counselors") ? (
              <Button
                active={location.pathname.startsWith(Section.PRIORITY_EVENTS)}
                onClick={() => onChangeRoute(Section.PRIORITY_EVENTS)}
              >
                Приоритеты
              </Button>
            ) : null}
            {checkPermission("users") &&
            checkPermission("users_mark_checked") ? (
              <Button
                active={location.pathname.startsWith(Section.UNCHECKED_USERS)}
                onClick={() => onChangeRoute(Section.UNCHECKED_USERS)}
              >
                Проверки
              </Button>
            ) : null}
            {checkPermission("screen_stats") ? (
              <Button
                active={location.pathname.startsWith(Section.SCREEN_STATS)}
                onClick={() => onChangeRoute(Section.SCREEN_STATS)}
              >
                Статистика
              </Button>
            ) : null}
            {checkPermission("screen_stats") ? (
              <Button
                active={location.pathname.startsWith(Section.GRAPHS)}
                onClick={() => onChangeRoute(Section.GRAPHS)}
              >
                Графики
              </Button>
            ) : null}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            <Button active onClick={() => onChangeRoute(Section.LOGIN)}>
              Вход
            </Button>
          </div>
        </React.Fragment>
      )}

      <Add onClick={onAdd}>
        <img src="/img/plus-circle.svg" alt="Add" />
      </Add>
    </Container>
  )
}
