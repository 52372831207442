import { AxiosResponse } from "axios"
import { Admin } from "../types/entity"
import axios from "./axios"
import { putHeaders } from "./storage"

export default class AuthApi {
  static async check(): Promise<AxiosResponse<Admin>> {
    try {
      const response = await axios({
        url: "/admin/check",
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot check auth`)
      throw e
    }
  }
}
