import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { EntityItem } from "../types/entity"
import { getOrbStyle } from "../utils"

const Container = styled.div`
  margin-right: 90px;
  padding: 30px 0;
  overflow-y: auto;
  width: 250px;
  :-webkit-scrollbar {
    display: none;
  }
`

const Element = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${({ active }) => (active ? "#000000" : "#979797")};
  margin-bottom: 16px;
  cursor: pointer;
`

const ElementText = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

const StatusOrb = styled.div`
  height: 10px;
  width: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`

type Props = {
  items: EntityItem[]
  onSelect: (item: EntityItem) => void
  isLast: boolean
  loadMore(page: number): void
  active?: string
}

export default function EntityList(props: Props) {
  const location = useLocation()
  return (
    <React.Fragment>
      <Container>
        <InfiniteScroll
          useWindow={false}
          initialLoad={false}
          loadMore={props.loadMore}
          hasMore={!props.isLast}
          threshold={120}
        >
          {props.items.map((item) => (
            <Element
              key={item.id}
              active={
                props.active && props.active === item.id
                  ? true
                  : location.pathname.includes(item.id)
              }
              onClick={() => {
                props.onSelect(item)
              }}
            >
              <StatusOrb style={getOrbStyle(item.moderationFlag)} />
              <ElementText>{item.text}</ElementText>
            </Element>
          ))}
        </InfiniteScroll>
      </Container>
    </React.Fragment>
  )
}
