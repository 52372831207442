import React, { ReactNode, useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import AuthApi from "../api/AuthApi"
import qs from "qs"
import AppContext from "../store/AppContext"

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
  extractToken: boolean
  children?: ReactNode
}

export default function AuthCheck(props: Props) {
  const history = useHistory()
  const location = useLocation()
  const { authenticated, setAuthenticated, setChecking } =
    useContext(AppContext)

  const extractToken = () => {
    const result = qs.parse(location.search)
    const token = result["?token"]
    console.log(`Token`, token)
    if (token) {
      localStorage.setItem("token", String(token))
    }
  }

  const checkAuth = async () => {
    try {
      if (props.extractToken) {
        extractToken()
      }
      setChecking(true)
      await AuthApi.check()
      setAuthenticated(true)
    } catch (e) {
      history.replace("/login")
    } finally {
      setChecking(false)
    }
  }

  useEffect(() => {
    if (authenticated) {
      return
    }
    checkAuth()
  }, [location, authenticated])

  const text = authenticated
    ? "Мы бежим с тобой как будто от гепаРРРРРда"
    : "Кайф-кайф, это реально кайф..."

  return (
    <Container>
      <h1 style={{ textAlign: "center" }}>{text}</h1>
    </Container>
  )
}
