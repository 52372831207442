import React from "react"
import { Admin, City } from "../types/entity"

export interface AppContextProps {
  authenticated: boolean
  setAuthenticated: (v: boolean) => void
  checking: boolean
  setChecking: (v: boolean) => void
  chooseWindow: JSX.Element | null
  setChooseWindow: (v: JSX.Element | null) => void
  cities: City[]
  setCities: (v: City[]) => void
  admin: Admin
  setAdmin: (v: Admin) => void
}

export default React.createContext<AppContextProps>({
  authenticated: false,
  setAuthenticated: () => {},
  chooseWindow: null,
  setChooseWindow: () => {},
  checking: false,
  setChecking: () => {},
  cities: [],
  setCities: () => {},
  admin: {
    email: "",
    permissions: [],
    name: "",
    cities: [],
  },
  setAdmin: () => {},
})
