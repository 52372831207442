import { ReportPositiveFeedback } from "../../types/entity"
import { DateTime } from "luxon"
import styled from "styled-components"

const Text = styled.div`
  position: relative;
  padding: 35px 15px;
  max-width: 220px;
  word-break: break-word;
  margin: 15px;
  background-color: #c6e1c3;
  text-align: left;
  border-radius: 48px;
`

const Date = styled.div`
  position: absolute;
  bottom: 10px;
  right: 30px;
`

const RatingContainer = styled.div`
  border: 2px solid black;
  padding: 0.5rem 1rem;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
`

const RatingItem = styled.div`
  font-weight: bold;
  font-size: 12px;
`

const PositiveFeedback: React.FC<{ feedback: ReportPositiveFeedback }> = ({
  feedback,
}) => {
  const getSociability = (val: number) => {
    if (val === 5) {
      return "Поровну"
    }
    return val > 5 ? "Собеседник" : "Я"
  }
  return (
    <Text>
      <RatingContainer>
        <RatingItem>{`Событие: ${feedback.rating_event} / 10`}</RatingItem>
        <RatingItem>{`Узнал(а) что-то новое: ${feedback.rating_learned_new} / 10`}</RatingItem>
        <RatingItem>{`Интересный человек: ${feedback.rating_person} / 10`}</RatingItem>
        <RatingItem>
          {`Кто общительнее: ${getSociability(feedback.rating_sociability)} (${
            feedback.rating_sociability
          } / 10)`}
        </RatingItem>
      </RatingContainer>
      {feedback.text || ""}
      <Date>
        {DateTime.fromISO(feedback.created_at).toFormat("dd.MM.yyyy")}
      </Date>
    </Text>
  )
}

export default PositiveFeedback
