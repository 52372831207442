import { AxiosResponse } from "axios"
import {
  CustomEventData,
  OrganizationEventData,
  OrganizationEventEntity,
} from "../types/entity"
import axios from "./axios"
import { putHeaders } from "./storage"

export default class EventsApi {
  static async deleteOrganizationEvent(
    id: string
  ): Promise<AxiosResponse<{ result: OrganizationEventData }>> {
    try {
      const response = await axios.post(
        `/admin/organization_events/remove/${id}`,
        {},
        {
          headers: putHeaders(),
        }
      )

      return response
    } catch (e) {
      console.error(`Cannot remove organization event by id ${id}`, e)
      throw e
    }
  }

  static async getOrganizationEventDetails(
    id: string
  ): Promise<AxiosResponse<{ result: OrganizationEventData }>> {
    try {
      const response = await axios.get(
        `/admin/organization_events/details/${id}`,
        {
          headers: putHeaders(),
        }
      )

      return response
    } catch (e) {
      console.error(`Cannot get organization event by `, e)
      throw e
    }
  }

  static async getOrganizationEvents(
    cursor?: string,
    onlyPriority?: boolean,
    cityId?: string
  ): Promise<
    AxiosResponse<{
      results: OrganizationEventEntity[]
      next_cursor: string
      is_last: boolean
    }>
  > {
    try {
      const response = await axios.get("/admin/list/organization_events", {
        params: {
          ...(cursor ? { cursor } : {}),
          ...(onlyPriority ? { only_priority: "1" } : {}),
          ...(cityId ? { city_id: cityId } : {}),
        },
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get organization events`, e)
      throw e
    }
  }

  static async updateOrganizationEvent(
    id: string,
    form: any
  ): Promise<AxiosResponse<OrganizationEventData>> {
    try {
      const response = await axios.post(
        `/admin/organization_events/update/${id}`,
        form,
        {
          headers: putHeaders(),
        }
      )
      return response
    } catch (e) {
      console.error(`Cannot update organization event`, e)
      throw e
    }
  }

  static async updateOrganizationEventMedia(id: string, formData: FormData) {
    try {
      const response = await axios.post(
        `/admin/organization_events/media/${id}`,
        formData,
        {
          headers: putHeaders(),
        }
      )
      console.log(response)
      return response
    } catch (e) {
      console.error("Cannot upload photos")
      throw e
    }
  }

  static async createOrganizationEvent(
    form: any
  ): Promise<AxiosResponse<{ result: OrganizationEventData }>> {
    try {
      const response = await axios.post(
        `/admin/organization_events/create`,
        form,
        {
          headers: putHeaders(),
        }
      )
      return response
    } catch (e) {
      console.error(`Cannot create organization event`, e)
      throw e
    }
  }

  static async getCustomEvents(cursor?: string) {
    try {
      const response = await axios.get("/admin/list/custom_events", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })
      return response.data
    } catch (e) {
      console.error(`Cannot get custom events`, e)
      throw e
    }
  }
  static async getCustomEvent(
    id: string
  ): Promise<AxiosResponse<{ result: CustomEventData }>> {
    try {
      const response = await axios.get(`/admin/custom_events/details/${id}`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get custom event`, e)
      throw e
    }
  }

  static async deleteCustomEvent(
    id: string
  ): Promise<AxiosResponse<{ result: CustomEventData }>> {
    try {
      const response = await axios.post(
        `/admin/custom_events/remove/${id}`,
        {},
        {
          headers: putHeaders(),
        }
      )

      return response
    } catch (e) {
      console.error(`Cannot remove custom event by id ${id}`, e)
      throw e
    }
  }
}
