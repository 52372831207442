import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f0f0f0;
  border-radius: 32px;
  max-width: 200px;
  font-size: 30px;
  padding: 20px;
  box-sizing: border-box;
`
const DateNow = styled.div`
  color: #000;
`
const TimeNow = styled.div`
  color: #8e8e93;
`

const Time: React.FC = () => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date())
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Wrapper>
      <DateNow>{DateTime.fromJSDate(date).toFormat("dd.MM.yyyy")}</DateNow>
      <TimeNow>{DateTime.fromJSDate(date).toFormat("HH:mm")}</TimeNow>
    </Wrapper>
  )
}

export default Time
