import React, { useState, useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import Button from "./Button"
import ActionModal from "./ActionModal"
import { UserData, CustomEventData, EventCategory } from "../types/entity"
import { Flex } from "./Flex"

import AppContext from "../store/AppContext"
import MyImage from "./MyImage"
import EventsApi from "../api/EventsApi"
import UserApi from "../api/UserApi"
import CategoriesApi from "../api/CategoriesApi"
import BlockButton from "./UserButtons/BlockButton"
import UnblockButton from "./UserButtons/UnblockButton"
import UserInfo from "./UserInfo"
import { toastError } from "../toast"

const Hint = styled.div`
  font-size: 18px;
  color: #979797;
  margin-top: 30px;
  margin-bottom: 8px;
`
const Description = styled.div`
  color: #000;
  font-size: 24px;
`

const ModalButton = styled.span`
  margin: 0 50px;
  font-size: 24px;
  cursor: pointer;
`

const ImageWrapper = styled.div`
  min-width: 250px;
  min-height: 400px;
  max-width: 250px;
  max-height: 400px;
  border-radius: 8px;
`

const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  div {
    margin-top: 28px;
  }
`

const Images = styled.div`
  display: flex;
  align-items: center;
  max-width: 600px;
  overflow-x: auto;
`

export default function CustomEvent() {
  const { id } = useParams<{ id: string }>()

  const [data, setData] = useState<CustomEventData | null>(null)
  const [user, setUser] = useState<UserData | null>(null)
  const [categories, setCategories] = useState<EventCategory[]>([])

  const { setChooseWindow } = useContext(AppContext)

  useEffect(() => {
    loadCustomEvent(id)
    getCategories()
  }, [id])

  /// API
  const loadCustomEvent = async (id: string) => {
    try {
      const response = await EventsApi.getCustomEvent(id)
      // setUser(user.data.result)
      setData(response.data.result)
      getUser(response.data.result.user_id)
    } catch (e) {
      toastError(e)
    }
  }
  const getCategories = async () => {
    try {
      const response = await CategoriesApi.getAllCategories()
      setCategories(response.data.result.organizations)
    } catch (e) {
      console.error(`Cannot get categories`, e)
      toastError(e)
    }
  }

  const getUser = async (id: string) => {
    try {
      const response = await UserApi.getUser(id)
      setUser(response.data.result)
    } catch (e) {
      console.log("Cannot get user", e)
      toastError(e)
    }
  }

  const deleteEvent = async () => {
    try {
      setChooseWindow(null)
      const response = await EventsApi.deleteCustomEvent(id!)
      setData(response.data.result)
    } catch (e) {
      toastError(e)
    }
  }

  //JSX

  const onDelete = () => {
    const JSX = (
      <ActionModal
        title="Удалить событие?"
        onClose={() => setChooseWindow(null)}
      >
        <Flex justify="center">
          <ModalButton onClick={() => setChooseWindow(null)} role="button">
            Нет
          </ModalButton>
          <ModalButton onClick={() => deleteEvent()} role="button">
            Да
          </ModalButton>
        </Flex>
      </ActionModal>
    )
    setChooseWindow(JSX)
  }

  //
  if (!data) {
    return <div>Loading</div>
  }
  return (
    <>
      <Flex>
        <Flex basis="40%" direction="column">
          <Flex direction="column" justify="flex-start">
            <Hint>Имя</Hint>
            <Description>{data.title || "-"}</Description>
          </Flex>
          <Flex direction="column" justify="flex-start">
            <Hint>Категория</Hint>
            <Description>
              {categories?.find((category) => category.id === data.category_id)
                ?.label || "-"}
            </Description>
          </Flex>
          <Flex direction="column" justify="flex-start">
            <Hint>Свое место</Hint>
            <Description>{data.place_name || "-"}</Description>
          </Flex>
          {data.place ? (
            <Flex direction="column" justify="flex-start">
              <Hint>Место</Hint>
              <Description>{data.place.name}</Description>
            </Flex>
          ) : null}

          {!!data.media.length && (
            <Flex direction="column" justify="flex-start">
              <Hint>Фотографии</Hint>
              <Images>
                {data.media.map((photo, index) => (
                  <ImageWrapper key={index}>
                    <MyImage
                      readOnly
                      imgTitle={"photo"}
                      onUpload={() => {}}
                      imgSrc={photo.url}
                    />
                  </ImageWrapper>
                ))}
              </Images>
            </Flex>
          )}
        </Flex>
        <Flex basis="60%" direction="column">
          {user && (
            <>
              <UserInfo user={user} />
              <Buttons>
                <BlockButton
                  id={user.id}
                  active={!user.blocked_until}
                  reload={() => getUser(user.id)}
                />
                <UnblockButton
                  id={user.id}
                  active={!!user.blocked_until}
                  reload={() => getUser(user.id)}
                />
              </Buttons>
            </>
          )}
        </Flex>
      </Flex>
      <Flex justify="flex-start" mt="60px">
        {data?.deleted_at ? (
          <Button text="Событие удалено" onClick={() => {}} />
        ) : (
          <Button text="Удалить событие" active onClick={onDelete} />
        )}
      </Flex>
    </>
  )
}
