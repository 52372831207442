import styled from "styled-components"
import { API_HOST } from "../api/axios"

const Button = styled.button`
  background-color: black;
  padding: 1rem 3rem;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  border: 2px solid transparent;
  :hover {
    background-color: white;
    color: black;
    border: 2px solid black;
  }
`

export default function Login() {
  return (
    <div>
      <h1>Надо вход делоть</h1>

      <Button
        onClick={() => {
          window.location.href = `${API_HOST}/api/v1/admin/auth/google`
        }}
      >
        Войти
      </Button>
    </div>
  )
}
