import styled from "styled-components"
import { DateTime } from "luxon"
const Text = styled.div`
  position: relative;
  width: 420px;
  min-height: 100px;
  border-radius: 40px;
  margin-top: 40px;
  padding: 20px;
  border: 2px solid #ededed;
  font-size: 24px;
`
const Date = styled.div`
  position: absolute;
  bottom: 10px;
  right: 30px;
`
const ReportedReason: React.FC<{ text: string; date?: string | null }> = ({
  text,
  date,
}) => {
  return (
    <Text>
      {text}
      {date && <Date>{DateTime.fromISO(date).toFormat("dd.MM.yyyy")}</Date>}
    </Text>
  )
}

export default ReportedReason
