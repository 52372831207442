import React, { useState, useEffect, useRef } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useParams } from "react-router-dom"
import { Flex } from "./Flex"
import styled from "styled-components"
import Input from "./MyInput"
import ChatApi from "../api/ChatApi"
import { ChatMessage, UserData } from "../types/entity"
import { DateTime } from "luxon"
import { toastError } from "../toast"
import UserInfo from "./Feedbacks/UserInfo"
import UserApi from "../api/UserApi"

const Messages = styled.div`
  margin-top: 40px;
  padding: 30px 30px 20px 10px;
  border-bottom: 1px solid #fff;
  overflow-y: auto;
  height: 450px;
`
const MyMessage = styled.div<{ mine: boolean }>`
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  background-color: ${(props) => (props.mine ? "#3683bc" : "#ededed")};
  color: ${(props) => (props.mine ? "#fff" : "#000")};
  float: ${(props) => (props.mine ? "right" : "left")};
  margin-bottom: 30px;
  width: 51%;
  position: relative;
`
const MessageDate = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  color: #000;
  font-size: 10px;
`
const MessageStatus = styled.div<{ mine: boolean }>`
  position: absolute;
  bottom: 0;
  right: 10px;
  color: ${(props) => (props.mine ? "#fff" : "#000")};
  font-size: 10px;
`
const Divider = styled.div`
  margin-top: 8px;
  width: 100%;
  border-bottom: 2px solid #ededed;
`
const Empty = styled.div`
  color: grey;
  font-size: 18px;
  position: absolute;
  top: 50%;
  width: 250px;
  left: calc(50% + 250px);
`

const InputContainer = styled.div`
  flex-basis: 70%;
  margin-right: 4px;
`

const SendBtn = styled.span`
  cursor: pointer;
`

const Message: React.FC<{ msg: ChatMessage }> = ({ msg }) => {
  return (
    <>
      <MyMessage mine={msg.from_support}>
        {msg.text}
        <MessageDate>
          {DateTime.fromISO(msg.created_at)
            .toLocal()
            .toFormat("dd.MM.yyyy HH:mm")}
        </MessageDate>
        <MessageStatus mine={msg.from_support}>
          {msg.is_read ? "Read" : "Unread"}
        </MessageStatus>
      </MyMessage>
    </>
  )
}

const Chat: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [input, setInput] = useState("")
  const [cursor, setCursor] = useState<string>()
  const [isLast, setIsLast] = useState<boolean>(true)
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [user, setUser] = useState<UserData | null>(null)
  const wrapperRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    initialLoad()
  }, [id])

  // API

  const initialLoad = async () => {
    try {
      const response = await ChatApi.getMessages(id, undefined)
      const newUser = await UserApi.getUser(id)
      setUser(newUser.data.result)
      setCursor(response.data.next_cursor)
      setIsLast(response.data.is_last)
      setMessages(response.data.results)
      scrollToBottom()
    } catch (e) {
      toastError(e)
    }
  }

  const loadMessages = async (id: string) => {
    try {
      const response = await ChatApi.getMessages(id, cursor)
      setCursor(response.data.next_cursor)
      setIsLast(response.data.is_last)
      console.log(response.data.results)
      setMessages([...response.data.results, ...messages])
    } catch (e) {
      toastError(e)
    }
  }

  //

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  //
  const sendMessage = async () => {
    if (input.trim().length === 0) return
    try {
      setInput("")
      await ChatApi.sendMessage(id, input)
      await initialLoad()
    } catch (e) {
      toastError(e)
    }
  }

  const keyHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendMessage()
    }
  }

  const scrollToBottom = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
    }
  }

  return (
    <Flex direction="column" justify="space-between">
      {user && <UserInfo user={user} />}
      <Divider />

      <Messages ref={wrapperRef}>
        <InfiniteScroll
          useWindow={false}
          initialLoad={false}
          loadMore={() => loadMessages(id)}
          hasMore={!isLast}
          isReverse={true}
          threshold={120}
        >
          {messages.map((item) => (
            <Message key={item.id} msg={item} />
          ))}
        </InfiniteScroll>
      </Messages>

      {id && !messages.length && <Empty>No messages yet...</Empty>}

      <Flex justify="center" basis="50px" align="center">
        <InputContainer>
          <Input
            onKeyDown={keyHandler}
            name="input"
            value={input}
            onChange={changeHandler}
            placeholder="Сообщение"
          />
        </InputContainer>
        <SendBtn onClick={sendMessage} role="button">
          <img src="/img/send-btn.svg" alt="send" />
        </SendBtn>
      </Flex>
    </Flex>
  )
}

export default Chat
