import styled from "styled-components"

const Content = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
`

const Tick = styled.div<{ active?: boolean }>`
  height: 30px;
  width: 30px;
  border: ${({ active }) =>
    active ? "2px solid #000000" : "2px solid #ededed"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background-color: ${({ active }) => (active ? "#000000" : "transparent")};
`

const Text = styled.div`
  margin-left: 12px;
  font-size: 18px;
`

interface Props {
  text: string
  active: boolean
  marginTop?: string
  marginLeft?: string
  onChange: (state: boolean) => void
}

export default function Checkbox(props: Props) {
  const { active } = props
  return (
    <Content
      onClick={() => props.onChange(!active)}
      style={{
        marginLeft: props.marginLeft,
        marginTop: props.marginTop,
      }}
    >
      <Tick active={active}>
        {active ? <img src="/img/checkmark.svg" alt="Check" /> : null}
      </Tick>
      <Text>{props.text}</Text>
    </Content>
  )
}
