import React, { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import EventsApi from "../api/EventsApi"
import AppContext from "../store/AppContext"
import {
  EntityItem,
  EventCategory,
  ModerationFlag,
  OrganizationEventData,
  OrganizationEventKind,
} from "../types/entity"
import Button from "./Button"
import ChooseButton from "./ChooseButton"
import SearchWindow from "./SearchWindow"
import Input from "./MyInput"
import { Flex } from "./Flex"
import SearchApi from "../api/SearchApi"
import CategoriesApi from "../api/CategoriesApi"
import MyImage from "./MyImage"
import ActionModal from "./ActionModal"
import MultipleDatePicker from "./MultipleDatePicker"
import { OrganizationEventForm } from "../types/form"
import { toastError } from "../toast"
import CustomSelect from "./CustomSelect"
import { getCityNameByIata } from "../utils"
import Checkbox from "./Checkbox"

const InputContainer = styled.div`
  width: 467px;
  height: 60px;
  margin-bottom: 34px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const InputSmallContainer = styled.div`
  margin-left: 8px;
  width: 190px;
  height: 60px;
  margin-bottom: 34px;
  @media (max-width: 1400px) {
    width: 140px;
  }
  @media (max-width: 1200px) {
    width: 100px;
  }
`

const StyledSelect = styled(InputContainer)`
  select {
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 14px 24px;
    border: 2px solid #ededed;
    border-radius: 30px;
    background: transparent;
    font-size: 20px;
    color: black;
    resize: none;
    font-family: "Open Sans", sans-serif;
    :placeholder {
      color: #979797;
    }
  }
`

const StyledDatePicker = styled(InputContainer)`
  margin-bottom: 22px;
  width: 350px;
  height: 100%;
  display: flex;
`

const ImageWrapper = styled.div`
  width: 160px;
  height: 240px;
  border-radius: 40px;
  margin: 0 15px;
`

const ModalButton = styled.span`
  margin: 0 50px;
  font-size: 24px;
  cursor: pointer;
`

const Header = styled.h1`
  margin-left: 30px;
  margin-bottom: 44px;
`

const CreateContainer = styled.div`
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

type Props = {
  withPriority?: boolean
}

export default function OrganizationEvent(props: Props) {
  const { id } = useParams<{ id: string | undefined }>()

  const [form, setForm] = useState<OrganizationEventForm>({
    title: "",
    category_id: "",
    organization_id: "",
    organization_label: "",
    price: 0,
    media: [],
    happens_at: [],
    url: "",
    city_id: "",
    kind: OrganizationEventKind.RECURRING_FOREVER,
    priority: 0,
  })
  const [event, setEvent] = useState<OrganizationEventData | null>(null)

  const [formData, setFormData] = useState(new Map<number, Blob | null>())
  const [omitPrice, setOmitPrice] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

  const [categories, setCategories] = useState<EventCategory[]>([])
  const { setChooseWindow, cities } = useContext(AppContext)
  const history = useHistory()

  // API

  const search = async (query: string): Promise<EntityItem[]> => {
    try {
      const response = await SearchApi.searchOrganizations(query)
      return response.data.results.map((r) => ({
        id: r.id,
        text: `${r.deleted_at ? "[DELETED] " : ""}${
          !r.available ? "[DISABLED] " : ""
        }${r.name}`,
        moderationFlag: ModerationFlag.OK,
      }))
    } catch (e) {
      toastError(e)
      const emptyArray: EntityItem[] = []
      return new Promise((resolve) => resolve(emptyArray))
    }
  }

  const getCategories = async () => {
    try {
      const response = await CategoriesApi.getAllCategories()
      setCategories(response.data.result.organizations)
    } catch (e) {
      toastError(e)
    }
  }

  const searchCategory = async () => {
    try {
      return categories.map((r) => ({
        id: r.id,
        text: r.label,
        moderationFlag: ModerationFlag.OK,
      }))
    } catch (e) {
      console.error(`Cannot select categories`, e)
      toastError(e)
      return []
    }
  }

  const getDetails = async (itemId: string) => {
    try {
      setLoading(true)
      const response = await EventsApi.getOrganizationEventDetails(itemId)
      initForm(response.data.result)
    } catch (e) {
      toastError(e)
      console.error(`Cannot get details for organization event`, e)
    } finally {
      setLoading(false)
    }
  }
  //

  //DID MOUNT

  useEffect(() => {
    if (id) {
      getDetails(id)
    }
  }, [id])

  useEffect(() => {
    getCategories()
  }, [])

  //

  //FORM

  const initForm = (data: OrganizationEventData) => {
    const {
      title,
      category_id,
      price,
      media,
      organization_id,
      url,
      happens_at,
      kind,
      place,
      city_id,
      priority,
    } = data
    setForm({
      ...form,
      category_id,
      organization_id,
      organization_label: place.name,
      price: !!price ? price : 0,
      media,
      title,
      url: !!url ? url : "",
      happens_at,
      kind,
      city_id,
      priority,
    })
    setEvent(data)
    setOmitPrice(price === null)
  }

  const prepareForm = (): Record<string, any> => {
    const deletion = ["organization_label"]
    const nullables = ["price", "url"]
    return (Object.keys(form) as Array<keyof typeof form>)
      .filter((key) => !deletion.includes(key))
      .reduce<any>((acc, value) => {
        if (value === "price" && omitPrice) {
          acc[value] = null
          return acc
        }
        if (nullables.includes(value) && !form[value] && form[value] !== 0) {
          console.log(`Nullable so will be omitted`, value)
          return acc
        }
        acc[value] = form[value]
        return acc
      }, {})
  }

  const onUpdate = async () => {
    if (id) {
      setDisableSubmit(true)
      const newForm = prepareForm()
      try {
        const newFormData = generateFormData()
        await EventsApi.updateOrganizationEvent(id, newForm)
        if (formData.size) {
          await EventsApi.updateOrganizationEventMedia(id, newFormData)
        }
        getDetails(id)
      } catch (e) {
        console.log(e)
        toastError(e)
      }
      setDisableSubmit(false)
    }
  }

  const onSave = async () => {
    if (!id) {
      if (!form.city_id) {
        return toastError([{ message: "Выбери город" }])
      }
      setDisableSubmit(true)
      const newForm = prepareForm()
      try {
        const newFormData = generateFormData()
        const response = await EventsApi.createOrganizationEvent(newForm)
        await EventsApi.updateOrganizationEventMedia(
          response.data.result.id,
          newFormData
        )
        setDisableSubmit(false)
        history.push(`/organization_events/${response.data.result.id}`)
      } catch (e) {
        console.log(e)
        toastError(e)
      }
    }
  }

  const generateFormData = (): FormData => {
    let keep: string[] = []
    form.media.forEach((item) => keep.push(item.url))
    keep.filter((item, index) => {
      if (!formData.get(index)) {
        return true
      }
      return false
    })
    let photos: Blob[] = []
    const newFormData = new FormData()
    for (let i = 0; i < 3; i++) {
      if (!!formData.get(i)) {
        photos.push(formData.get(i)!)
      }
    }
    photos.forEach((photo) => {
      newFormData.append("photos", photo)
    })
    newFormData.append("keep", JSON.stringify(keep))
    return newFormData
  }

  //

  // Change Handlers

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const organizationHandler = (organization: EntityItem | null) => {
    if (organization) {
      setForm({
        ...form,
        organization_id: organization.id,
        organization_label: organization.text,
      })
      return setChooseWindow(null)
    }
    return setChooseWindow(null)
  }

  const fileHandler = (value: Blob | null, index: number) => {
    if (value === null) {
      setForm({ ...form, media: form.media.filter((_, i) => i !== index) })
    }
    setFormData(formData.set(index, value))
  }

  //

  // Choose Buttons

  const pickCategory = () => {
    const onClose = (item: EntityItem | null) => {
      setChooseWindow(null)
      if (!item) return
      setForm({ ...form, category_id: item.id })
    }
    const JSX = (
      <SearchWindow
        title="Категория"
        onSearch={searchCategory}
        onClose={(item) => onClose(item)}
      />
    )
    setChooseWindow(JSX)
  }

  const organizationOpen = () => {
    const JSX = (
      <SearchWindow
        title="Организация"
        onSearch={search}
        onClose={(item) => organizationHandler(item)}
      />
    )
    setChooseWindow(JSX)
  }

  const deleteEvent = async () => {
    try {
      setDisableSubmit(true)
      setChooseWindow(null)
      const response = await EventsApi.deleteOrganizationEvent(id!)
      setEvent(response.data.result)
    } catch (e) {
      toastError(e)
    } finally {
      setDisableSubmit(false)
    }
  }

  const onDelete = () => {
    const JSX = (
      <ActionModal
        title="Удалить событие"
        onClose={() => setChooseWindow(null)}
      >
        <ModalButton onClick={() => setChooseWindow(null)} role="button">
          Нет
        </ModalButton>
        <ModalButton onClick={() => deleteEvent()} role="button">
          Да
        </ModalButton>
      </ActionModal>
    )

    setChooseWindow(JSX)
  }

  const CityPicker = () => (
    <CustomSelect
      title="Город события"
      name="city_id"
      options={cities
        .filter((c) => c.is_available)
        .map((c) => ({ value: c.id, label: getCityNameByIata(c.iata) }))}
      value={form.city_id}
      onChange={changeHandler}
    />
  )

  if (loading) {
    return <Flex direction="column">Loading...</Flex>
  }

  if (!id)
    return (
      <CreateContainer>
        <Flex direction="column">
          <Header>Создать событие</Header>
          <Flex align="center">
            <InputContainer>
              <Input
                placeholder="Название"
                value={form.title}
                name="title"
                onChange={changeHandler}
              />
            </InputContainer>
            <InputSmallContainer>
              <ChooseButton
                hint="Категория"
                content={
                  categories.find((c) => c.id === form.category_id)?.label ||
                  "Нет"
                }
                onChange={pickCategory}
              />
            </InputSmallContainer>
          </Flex>
          <Flex mb="22px">
            <ChooseButton
              hint="Организация"
              content={form.organization_label}
              onChange={organizationOpen}
            />
          </Flex>

          <StyledDatePicker>
            <MultipleDatePicker
              maximumDates={3}
              title="Даты проведения"
              value={form.happens_at}
              onChange={(dates) => setForm({ ...form, happens_at: dates })}
            />
          </StyledDatePicker>
          <StyledSelect>
            <select value={form.kind} onChange={changeHandler} name="kind">
              <option value={OrganizationEventKind.RECURRING_FOREVER}>
                Постоянно
              </option>
              <option value={OrganizationEventKind.RECURRING_TEMPORARILY}>
                Временно
              </option>
              <option value={OrganizationEventKind.ONE_TIME}>Один раз</option>
            </select>
          </StyledSelect>

          <CityPicker />

          <InputContainer>
            <Input
              placeholder="Ссылка"
              value={form.url}
              name="url"
              onChange={changeHandler}
            />
          </InputContainer>
          <InputContainer>
            <Checkbox
              text="Без цены"
              active={omitPrice}
              onChange={(v) => {
                setOmitPrice(v)
              }}
            />
          </InputContainer>
          {!omitPrice ? (
            <InputContainer>
              <Input
                placeholder="Стоимость"
                value={form.price}
                type="number"
                name="price"
                onChange={changeHandler}
              />
            </InputContainer>
          ) : null}
          <Flex>
            <ImageWrapper>
              <MyImage
                imgTitle="Картинка"
                onUpload={(value) => {
                  fileHandler(value, 0)
                }}
                imgSrc={form.media[0]?.url}
              />
            </ImageWrapper>
            <ImageWrapper>
              <MyImage
                imgTitle="Картинка"
                onUpload={(value) => {
                  fileHandler(value, 1)
                }}
                imgSrc={form.media[1]?.url}
              />
            </ImageWrapper>
            <ImageWrapper>
              <MyImage
                imgTitle="Картинка"
                onUpload={(value) => {
                  fileHandler(value, 2)
                }}
                imgSrc={form.media[2]?.url}
              />
            </ImageWrapper>
          </Flex>
          <Flex mt="60px" align="center">
            <Button text="Создать" active={!disableSubmit} onClick={onSave} />
          </Flex>
        </Flex>
      </CreateContainer>
    )

  return (
    <Flex direction="column">
      <Flex align="center">
        <InputContainer>
          <Input
            placeholder="Название"
            value={form.title}
            name="title"
            onChange={changeHandler}
          />
        </InputContainer>
        <InputSmallContainer>
          <ChooseButton
            hint="Категория"
            content={
              categories.find((c) => c.id === form.category_id)?.label || "Нет"
            }
            onChange={pickCategory}
          />
        </InputSmallContainer>
      </Flex>

      <CityPicker />

      <Flex mb="22px">
        <ChooseButton
          hint="Организация"
          content={form.organization_label}
          onChange={organizationOpen}
        />
      </Flex>

      <StyledDatePicker>
        <MultipleDatePicker
          maximumDates={3}
          title="Даты события"
          value={form.happens_at}
          onChange={(dates) => setForm({ ...form, happens_at: dates })}
        />
      </StyledDatePicker>
      <StyledSelect>
        <select value={form.kind} onChange={changeHandler} name="kind">
          <option value={OrganizationEventKind.RECURRING_FOREVER}>
            Постоянно
          </option>
          <option value={OrganizationEventKind.RECURRING_TEMPORARILY}>
            Временно
          </option>
          <option value={OrganizationEventKind.ONE_TIME}>Один раз</option>
        </select>
      </StyledSelect>

      <InputContainer>
        <Input
          placeholder="Ссылка"
          value={form.url}
          name="url"
          onChange={changeHandler}
        />
      </InputContainer>
      <InputContainer>
        <Checkbox
          text="Без цены"
          active={omitPrice}
          onChange={(v) => {
            setOmitPrice(v)
          }}
        />
      </InputContainer>
      {!omitPrice ? (
        <InputContainer>
          <Input
            placeholder="Стоимость"
            value={form.price}
            type="number"
            name="price"
            onChange={changeHandler}
          />
        </InputContainer>
      ) : null}
      {props.withPriority ? (
        <InputContainer>
          <div>Приоритет</div>
          <Input
            placeholder="Приоритет"
            value={form.priority}
            type="number"
            name="priority"
            onChange={changeHandler}
          />
        </InputContainer>
      ) : null}
      <Flex>
        <ImageWrapper>
          <MyImage
            imgTitle="Картинка"
            onUpload={(value) => fileHandler(value, 0)}
            imgSrc={form.media[0]?.url}
          />
        </ImageWrapper>
        <ImageWrapper>
          <MyImage
            imgTitle="Картинка"
            onUpload={(value) => fileHandler(value, 1)}
            imgSrc={form.media[1]?.url}
          />
        </ImageWrapper>
        <ImageWrapper>
          <MyImage
            imgTitle="Картинка"
            onUpload={(value) => fileHandler(value, 2)}
            imgSrc={form.media[2]?.url}
          />
        </ImageWrapper>
      </Flex>
      <div
        style={{
          marginTop: "2rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button text="Сохранить" active={!disableSubmit} onClick={onUpdate} />
        {event?.deleted_at ? (
          <Button text="Событие удалено" onClick={() => {}} />
        ) : (
          <Button text="Удалить" active={!disableSubmit} onClick={onDelete} />
        )}
      </div>
    </Flex>
  )
}
