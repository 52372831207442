export enum ModerationFlag {
  NONE = -1,
  OK = 0,
  WARNED = 1,
  BLOCKED = 2,
  ACTION_REQUIRED = 3,
}

export type EntityItem = {
  id: string
  moderationFlag: ModerationFlag
  text: string
  imageUrl?: string
}

export enum OrganizationEventKind {
  ONE_TIME = "one_time",
  RECURRING_TEMPORARILY = "recurring_temp",
  RECURRING_FOREVER = "recurring_forever",
}

export type EntityItemMedia = {
  url: string
}

export type OrganizationData = {
  id: string
  name: string
  avatar_url: string | null
  background_url: string | null
  url: string | null
  ticket_url: string | null
  verified: boolean
  instagram: string | null
  facebook: string | null
  youtube: string | null
  description: string | null

  available: boolean
  deleted_at: Date | null
  created_at: Date
  updated_at: Date

  like_count?: number
}
export type OrganizationEventData = {
  category_id: string
  city_id: string
  id: string
  kind: OrganizationEventKind
  like_count: number
  media: EntityItemMedia[]
  organization_id: string
  place: OrganizationData
  price: number | null
  title: string
  url: string | null
  type: "organization_event"
  priority: number

  active_until: string | null
  happens_at: string[]
  created_at: string
  deleted_at: null
  updated_at: string
}

export type OrganizationEventEntity = {
  created_at: string
  deleted_at: null | string
  id: string
  title: string
  type: "organization_event"
}

export type EventCategory = {
  id: string
  label: string
  value: number
}

export type EventCategoriesMetadata = {
  users: EventCategory[]
  organizations: EventCategory[]
}

export type UserData = {
  id: string
  allow_seach: boolean
  approved: boolean
  avatar_url: string | null
  birthday: string | null
  blocked_until: string | null
  city_id: string | null
  company: string | null
  custom_delete_reason: string | null
  delete_reasons: []
  description: string | null
  first_name: string | null
  free_custom_events: number
  free_invitations: number
  gender: number
  interests: { id: string; value: string }[]
  intentions: { value: string; label: string }[]
  job: string | null
  orientation: number | null
  phone: string | null
  checked: boolean

  subscription_expires_at: string | null
  deleted_at: string | null
  created_at: string
  updated_at: string | null
}

export type CustomEventData = {
  user_id: string
  id: string

  category_id: string | null
  expire_kind: string
  finalized: boolean
  happens_at: string[]
  kind: OrganizationEventKind
  media: EntityItemMedia[]
  place: OrganizationData
  place_name: string | null
  place_organization_id: string | null
  title: string
  type: string

  updated_at: string | null
  created_at: string
  deleted_at: string | null
  expires_at: string
  active_until: string | null
}

export type SearchType =
  | CustomEventData[]
  | UserData[]
  | OrganizationData[]
  | OrganizationEventData[]

//

export type ReportType =
  | OrganizationEventReport
  | UserReport
  | CustomEventReport
  | OrganizationReport
  | MeetingReport
  | UserData

export type OrganizationEventReport = {
  id: string
  created_at: string
  report_author: UserData
  report_reason: string
  reported_by: string
  reported_event: OrganizationEventData
  reported_event_id: string
  status: string
  text: string | null
  updated_at: string | null
}

export type UserReport = {
  id: string
  created_at: string
  report_author: UserData
  report_reason: string
  reported_by: string
  reported_user: UserData
  reported_user_id: string
  status: string
  text: string | null
  updated_at: string | null
}

export type CustomEventReport = {
  id: string
  created_at: string
  updated_at: string | null
  report_author: UserData
  report_reason: string
  reported_by: string
  reported_event: OrganizationEventData
  reported_event_id: string
  status: string
  text: string | null
}

export type OrganizationReport = {
  id: string

  created_at: string
  updated_at: string | null

  report_author: UserData
  report_reason: string
  reported_by: string
  reported_organization: OrganizationData
  reported_organization_id: string

  status: string
  text: string | null
}

export type MeetingReport = {
  id: string
  accepted: boolean
  created_at: string
  custom_event_id: null | string
  deleted_at: null | string
  expires_at: string
  invited_user: UserData
  invited_user_id: string
  is_unsent: boolean
  meeting_datetime: string
  meeting_set_by: string
  message: null | string
  negative_feedbacks: ReportNegativeFeedback[]
  organization_event_id: string
  positive_feedbacks: ReportPositiveFeedback[]
  updated_at: string | null
  user: UserData
  user_id: string
}

export type ReportNegativeFeedback = {
  created_at: string
  id: string
  invitation_id: string
  reason: string
  user_id: string
}
export type ReportPositiveFeedback = {
  created_at: string
  id: string
  invitation_id: string
  rating_event: number
  rating_learned_new: number
  rating_person: number
  rating_sociability: number
  text: string | null
  user_id: string
}

export type ChatMessage = {
  created_at: string
  from_support: boolean
  id: string
  is_read: boolean
  kind: string
  text: string
  updated_at: string | null
  user_id: string
}

export enum FeedbackType {
  MEETING = "meeting",
  DELETE = "delete",
  EVENTS = "events",
  ORGANIZATIONS = "organizations",
  CUSTOM_EVENTS = "custom_events",
  USERS = "users",
}

export type CityIata =
  | "MOW"
  | "LED"
  | "NYC"
  | "KZN"
  | "SVX"
  | "ROV"
  | "GOJ"
  | "VOZ"
  | "KUF"
  | "OVB"
  | "KGD"

export type City = {
  id: string
  iata: CityIata
  is_available: boolean
}

export type AdminPermission =
  | "all"
  | "organization_events"
  | "modify_organization_events"
  | "organizations"
  | "modify_organizations"
  | "custom_events"
  | "modify_custom_events"
  | "support_chats"
  | "meeting_feedbacks"
  | "users"
  | "reports"
  | "counselors"
  | "modify_counselors"
  | "users_mark_checked"
  | "screen_stats"
  | "adapty"

export type Admin = {
  email: string
  name: string
  permissions: AdminPermission[]
  cities: CityIata[]
}

export type Counselor = {
  id: string
  event_ids: string[]
  url: string | null
  description: string | null
  title: string
  image_url: string
  instagram: string | null
  active_until: string | Date
  created_at: string
  deleted_at: string
  events: OrganizationEventData[]
  text_color: string
  color: string
}

export type ScreenStats = {
  total_users: number
  users_today: number
  total_invitations: number
  invitations_today: number
  total_matches: number
  matches_today: number
  total_messages: number
  messages_today: number
  total_custom_events: number
  custom_events_today: number
  total_want_to_go: number
  want_to_go_today: number
}
