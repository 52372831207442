import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import Button from "./Button"
import { Flex } from "./Flex"
import Input from "./MyInput"
import TextArea from "./MyTextArea"
import { useParams } from "react-router-dom"
import { Counselor, EntityItem, ModerationFlag } from "../types/entity"
import { toastError } from "../toast"
import CounselorsApi from "../api/CounselorsApi"
import CounselorEvents from "./counselors/CounselorEvents"
import EventsApi from "../api/EventsApi"
import AppContext from "../store/AppContext"

const InputContainer = styled.div`
  width: 400px;
  height: 60px;
  margin-bottom: 22px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const TextAreaContainer = styled.div`
  width: 400px;
  height: 160px;
  margin-bottom: 22px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const CreateContainer = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

const SampleChip = styled.div`
  border-radius: 24px;
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`

const CounselorData: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [data, setData] = useState<Counselor | undefined>()
  const [editableEvents, setEditableEvents] = useState<EntityItem[]>([])
  const [form, setForm] = useState<Partial<Counselor>>({
    title: "",
    instagram: "",
    description: "",
    url: "",
    text_color: "#FFFFFF",
    color: "#000000",
  })
  const { setChooseWindow } = useContext(AppContext)

  const loadData = async () => {
    try {
      const response = await CounselorsApi.getCounselorDetails(id)

      setData(response.data.result)
      setEditableEvents(
        response.data.result.events.map((ev) => ({
          id: ev.id,
          text: `[${ev.priority}] ${ev.title}`,
          moderationFlag: ModerationFlag.OK,
          imageUrl: ev.media.length ? ev.media[0].url : undefined,
        }))
      )
      setForm(response.data.result)
    } catch (e) {
      toastError(e)
    }
  }

  useEffect(() => {
    loadData()
  }, [id])

  const onDelete = async () => {
    try {
      setDisableSubmit(true)
      const response = await CounselorsApi.deleteCounselor(id)

      setData(response.data.result)
    } catch (e) {
      toastError(e)
    } finally {
      setDisableSubmit(false)
    }
  }

  const prepareForm = (): Record<string, any> => {
    const deletion: string[] = [
      "image_url",
      "events",
      "deleted_at",
      "created_at",
    ]
    const f = {
      ...form,
      event_ids: editableEvents.map((v) => v.id),
    }
    return (Object.keys(f) as Array<keyof typeof f>)
      .filter((key) => !deletion.includes(key))
      .reduce<any>((acc, key) => {
        if (f[key] === undefined || f[key] === null) {
          return acc
        }
        if (key === "event_ids") {
          acc[key] = JSON.stringify(f[key])
          return acc
        }
        if (key === "active_until") {
          const val = f[key] as Date
          acc[key] = new Date(val).toISOString()
          return acc
        }
        acc[key] = f[key]
        return acc
      }, {})
  }

  const generateFormData = (f: Record<string, any>): FormData => {
    const newFormData = new FormData()
    Object.keys(f).forEach((k) => {
      console.log(`Adding key ${k} with value ${f[k]}`)
      newFormData.append(k, f[k])
    })
    return newFormData
  }

  const onUpdate = async () => {
    if (id) {
      setDisableSubmit(true)
      try {
        const newForm = prepareForm()
        console.log(`Prepared form`)
        const newFormData = generateFormData(newForm)
        await CounselorsApi.updateCounselor(newFormData, id)
        await loadData()
      } catch (e) {
        console.log(e)
        toastError(e)
      }
      setDisableSubmit(false)
    }
  }

  const onSubmitPriority = async (item: EntityItem, val: number) => {
    try {
      setDisableSubmit(true)
      await EventsApi.updateOrganizationEvent(item.id, {
        priority: val,
      })
      await loadData()
      setChooseWindow(null)
    } catch (e) {
      toastError(e)
      console.error(`Error at submit priority`, e)
    }
    setDisableSubmit(false)
  }

  if (!data) {
    return <div />
  }

  return (
    <CreateContainer>
      <SampleChip
        style={{ backgroundColor: data.color, color: data.text_color }}
      >
        {data.title}
      </SampleChip>
      <Flex>
        <Flex direction="column">
          <ImagesContainer>
            <img
              src={data.image_url}
              style={{
                borderRadius: "50%",
                width: 200,
                height: 200,
                objectFit: "cover",
              }}
            />
          </ImagesContainer>
          {data.url ? (
            <InputContainer>
              <Input
                name="url"
                disabled
                onChange={() => {}}
                value={data.url || ""}
                placeholder="Ссылка на лендинг"
              />
            </InputContainer>
          ) : null}
          {data.instagram ? (
            <InputContainer>
              <Input
                name="instagram"
                disabled
                value={data.instagram || ""}
                onChange={() => {}}
                placeholder="Инстаграм"
              />
            </InputContainer>
          ) : null}
          {data.description ? (
            <TextAreaContainer>
              <TextArea
                value={data.description || ""}
                name="description"
                onChange={() => {}}
                disabled
                placeholder="Описание"
              />
            </TextAreaContainer>
          ) : null}

          <div style={{ fontSize: 16, fontWeight: "bold" }}>
            Рекомендация активна до:{" "}
            {new Date(data.active_until).toLocaleString()}
          </div>

          <CounselorEvents
            events={editableEvents}
            onChange={(evs) => {
              setEditableEvents(evs)
            }}
            onSubmitPriority={onSubmitPriority}
          />
        </Flex>
      </Flex>
      <Flex mt="20px" justify="space-between">
        <Button text="Сохранить" active={!disableSubmit} onClick={onUpdate} />
        {data.deleted_at ? (
          <Button text="Рекомендация удалена" onClick={() => {}} />
        ) : (
          <Button
            text="Удалить рекомендацию"
            active={!disableSubmit}
            onClick={onDelete}
          />
        )}
      </Flex>
    </CreateContainer>
  )
}

export default CounselorData
