import React, { useContext, useState } from "react"
import ActionModal from "../ActionModal"
import MyTextArea from "../MyTextArea"
import AppContext from "../../store/AppContext"
import Button from "../Button"
import styled from "styled-components"
import { Flex } from "../Flex"
import ChatApi from "../../api/ChatApi"

const TextAreaWrapper = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
`
const ModalButton = styled.span`
  margin: 0 50px;
  font-size: 24px;
  cursor: pointer;
`

const Modal: React.FC<{
  onClose(): void
  onYes(reason: string): void
}> = ({ onClose, onYes }) => {
  const [input, setInput] = useState("")

  return (
    <ActionModal onClose={onClose} title="Написать сообщение">
      <TextAreaWrapper>
        <MyTextArea
          value={input}
          placeholder="Сообщение"
          onChange={(e) => setInput(e.target.value)}
        />
      </TextAreaWrapper>

      <Flex justify="center" mt="30px">
        <ModalButton onClick={onClose}>Отменить</ModalButton>
        <ModalButton
          onClick={() => {
            if (!input) return
            onYes(input)
          }}
        >
          Отправить
        </ModalButton>
      </Flex>
    </ActionModal>
  )
}

const ChatButton: React.FC<{ id: string; active: boolean; reload(): void }> = ({
  id,
  active,
  reload,
}) => {
  const { setChooseWindow } = useContext(AppContext)
  const blockRequest = async (input: string) => {
    try {
      await ChatApi.sendMessage(id, input)
      reload()
    } catch (e) {
      console.log(e)
    } finally {
      setChooseWindow(null)
    }
  }

  const blockUser = () => {
    setChooseWindow(
      <Modal onClose={() => setChooseWindow(null)} onYes={blockRequest} />
    )
  }

  return <Button active={active} onClick={blockUser} text="Написать" />
}

export default ChatButton
