import React, { useEffect, useState } from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router"
import styled from "styled-components"
import ChatApi, { GetSupportChatItem } from "../api/ChatApi"
import Chat from "../components/Chat"
import EntityList from "../components/EntityList"
import { Flex } from "../components/Flex"
import { toastError } from "../toast"
import { EntityItem, ModerationFlag } from "../types/entity"

const Container = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

const Chats: React.FC = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [cursor, setCursor] = useState<string>()
  const [isLast, setIsLast] = useState<boolean>(true)
  const [items, setItems] = useState<EntityItem[]>([])

  const loadElements = async () => {
    try {
      const response = await ChatApi.getSupportChats(cursor)
      const data = response.data
      setCursor(data.next_cursor)
      setIsLast(data.is_last)
      setItems([...items, ...mapToEntityList(data.results)])
    } catch (e) {
      toastError(e)
    }
  }

  const mapToEntityList = (items: GetSupportChatItem[]) => {
    return items.map((item) => {
      const author = item.user.first_name || item.user.phone || "User"
      const text = `${author}: ${item.text}`
      return {
        id: item.user.id,
        moderationFlag: item.from_support
          ? ModerationFlag.NONE
          : ModerationFlag.ACTION_REQUIRED,
        text,
      }
    })
  }

  useEffect(() => {
    loadElements()
  }, [])

  return (
    <>
      <Flex direction="column">
        <EntityList
          isLast={isLast}
          loadMore={loadElements}
          items={items}
          onSelect={(item) => {
            history.push(`${path}/${item.id}`)
          }}
        />
      </Flex>
      <Container>
        <Switch>
          <Route exact path={path}>
            <h1 style={{ textAlign: "center" }}>Чат</h1>
          </Route>
          <Route path={`${path}/:id`}>
            <Chat />
          </Route>
        </Switch>
      </Container>
    </>
  )
}

export default Chats
