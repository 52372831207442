import React, { useState, useEffect } from "react"
import Input from "./MyInput"
import styled from "styled-components"
import useDebounce from "../hooks/useDebounce"

const InputWrapper = styled.div`
  width: 80%;
  height: 40px;
  background-color: #fff;
`

type SearchInputProps = {
  query(search: string): void
  initialLoad(): void
}

const SearchInput: React.FC<SearchInputProps> = ({ query, initialLoad }) => {
  const [value, setValue] = useState("")
  const debounce = useDebounce(value, 250)

  useEffect(() => {
    if (!!debounce.trim()) {
      query(debounce)
    } else {
      initialLoad()
    }
  }, [debounce])

  return (
    <InputWrapper>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Поиск..."
      />
    </InputWrapper>
  )
}

export default SearchInput
