import axios from "axios"

console.log(`Api host is ${process.env.REACT_APP_API_HOST}`)

export const API_HOST =
  process.env.REACT_APP_API_HOST || "https://champloo.joinapt.app"

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? API_HOST + "/api/v1"
      : "https://champloo.joinapt.app/api/v1",
})

export default instance
