import React from "react"
import styled from "styled-components"
import colors from "./colors"

type Keys = keyof typeof colors
type Values = typeof colors[Keys]
type StatsProps = {
  amount: number
  totalAmount: number
  color: Values
  label: string
  basis: string
}

const Container = styled.div<{ color: string; basis: string }>`
  position: relative;
  border-radius: 48px;
  color: #000;
  flex-basis: ${(props) => props.basis};
  background-color: ${(props) => props.color};
  padding: 32px;
  margin: 10px;
  font-weight: 600;
  height: auto;
  @media (max-width: 1200px) {
  }
  @media (max-width: 1000px) {
  }
`

const Label = styled.div`
  font-size: 24px;
  font-weight: 400;
  white-space: break-spaces;
  word-break: bread-word;
  @media (max-width: 1200px) {
    font-size: 20px;
  }
  @media (max-width: 1000px) {
    font-size: 16px;
  }
`

const Total = styled.div`
  position: absolute;
  top: 15px;
  right: 33px;
  font-size: 30px;
  @media (max-width: 1200px) {
    font-size: 24px;
  }
  @media (max-width: 1000px) {
    font-size: 20px;
  }
`

const Amount = styled.div`
  font-size: 54px;
  font-weight: 800;
  @media (max-width: 1200px) {
    font-size: 38px;
  }
  @media (max-width: 1000px) {
    font-size: 30px;
  }
`

const Bottom = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`

const Stats: React.FC<StatsProps> = ({
  color,
  label,
  totalAmount,
  amount,
  basis,
}) => {
  return (
    <Container basis={basis} color={color}>
      <Total>{totalAmount}</Total>
      <Bottom>
        <Amount>{amount}</Amount>
        <Label>{label}</Label>
      </Bottom>
    </Container>
  )
}

export default Stats
