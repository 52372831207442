import { DateTime } from "luxon"
import styled from "styled-components"
import { ReportNegativeFeedback } from "../../types/entity"

const Text = styled.div`
  position: relative;
  padding: 35px 15px;
  max-width: 220px;
  word-break: break-word;
  margin: 15px;
  background-color: #e8b0b0;
  text-align: left;
  border-radius: 70px;
  flex-basis: 50%;
`
const Date = styled.div`
  position: absolute;
  bottom: 10px;
  right: 30px;
`

const NegativeFeedback: React.FC<{ feedback: ReportNegativeFeedback }> = ({
  feedback,
}) => {
  return (
    <Text>
      {feedback.reason || "Negative Feedback"}{" "}
      <Date>
        {DateTime.fromISO(feedback.created_at).toFormat("dd.MM.yyyy")}
      </Date>
    </Text>
  )
}

export default NegativeFeedback
