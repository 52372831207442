import differenceInYears from "date-fns/differenceInYears"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import UserApi from "../api/UserApi"
import { toastError } from "../toast"
import { UserData } from "../types/entity"
import Button from "./Button"
import { Flex } from "./Flex"
import MyImage from "./MyImage"
import BlockButton from "./UserButtons/BlockButton"
import ChatButton from "./UserButtons/ChatButton"
import GiftButton from "./UserButtons/GiftButton"
import UnblockButton from "./UserButtons/UnblockButton"

const AvatarWrapper = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 31px;
`

const InfoBold = styled.div`
  font-size: 24px;
  color: #000;
`
const InfoLight = styled.div`
  font-size: 24px;
  color: #979797;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-left: 200px;
  div {
    margin-top: 28px;
  }
`

const Hint = styled.div`
  font-size: 16px;
  color: #979797;
  margin-top: 30px;
  margin-bottom: 4px;
`

type Props = {
  showChecked?: boolean
  hideGrantSubscription?: boolean
}

export default function User(props: Props) {
  const [user, setUser] = useState<UserData | null>(null)
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(false)

  // API

  const getUser = async () => {
    try {
      const response = await UserApi.getUser(id)
      setUser(response.data.result)
    } catch (e) {
      console.log(e)
      toastError(e)
    }
  }
  useEffect(() => {
    getUser()
  }, [id])

  const calculateAge = (dob: string): any => {
    const age = differenceInYears(new Date(), new Date(dob))
    return age
  }

  const markCheckedUser = async () => {
    try {
      if (!user) {
        return
      }
      setLoading(true)

      const response = await UserApi.markCheckedUser(user.id)
      setUser({
        ...user,
        checked: response.data.result.checked,
      })
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  const refreshAdaptyUser = async () => {
    try {
      if (!user) {
        return
      }
      setLoading(true)

      await UserApi.refreshAdaptyUser(user.id)
      await getUser()
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  if (!user) return null
  return (
    <Flex>
      <Flex direction="column">
        <AvatarWrapper>
          <MyImage
            imgSrc={!!user.avatar_url ? user.avatar_url : ""}
            imgTitle="Аватар"
            readOnly
            onUpload={(value) => {}}
          />
        </AvatarWrapper>
        <Flex direction="column" align="flex-start">
          <Hint>Общая информация</Hint>
          <InfoBold>
            {user.first_name},{" "}
            {user.birthday ? calculateAge(user.birthday) : null}
          </InfoBold>
          <InfoLight>{user.job}</InfoLight>
          <InfoLight>{user.company}</InfoLight>
          <InfoLight>{user.description}</InfoLight>
        </Flex>

        <Flex mt="25px" mb="15px" direction="column" align="flex-start">
          <Hint>Интересы</Hint>
          {user.interests.map((item) => (
            <InfoBold key={item.id}>{item.value}</InfoBold>
          ))}
        </Flex>

        <Flex mt="5px" mb="35px" direction="column" align="flex-start">
          <Hint>Намерения</Hint>
          {user.intentions.map((item) => (
            <InfoBold key={item.value}>{item.label}</InfoBold>
          ))}
        </Flex>
        <Hint>Персональные данные</Hint>
        <InfoBold>{user.phone}</InfoBold>

        {user.blocked_until ? (
          <React.Fragment>
            <Hint>Заблокирован до:</Hint>
            <InfoBold>
              {new Date(user.blocked_until).toLocaleDateString()}
            </InfoBold>
          </React.Fragment>
        ) : null}

        <React.Fragment>
          <Hint>Подписка до:</Hint>
          <InfoBold>
            {user.subscription_expires_at
              ? new Date(user.subscription_expires_at).toLocaleString()
              : "Подписки нет"}
          </InfoBold>
        </React.Fragment>
      </Flex>

      <Flex direction="column">
        <Buttons>
          {/* <ReportButton reload={getUser} active id={id} /> */}
          <BlockButton reload={getUser} active={!user.blocked_until} id={id} />
          {props.hideGrantSubscription ? null : (
            <GiftButton reload={getUser} active id={id} />
          )}
          <UnblockButton
            reload={getUser}
            active={!!user.blocked_until}
            id={id}
          />
          <ChatButton reload={getUser} active id={id} />

          {props.showChecked && user ? (
            <Button
              active={!loading && !user.checked}
              onClick={markCheckedUser}
              text={user.checked ? "Проверен успешно" : "Проверен"}
            />
          ) : null}

          {user ? (
            <Button
              active={!loading}
              onClick={refreshAdaptyUser}
              text="Adapty refresh"
            />
          ) : null}
        </Buttons>
      </Flex>
    </Flex>
  )
}
