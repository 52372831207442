import { ModerationFlag } from "./types/entity"

export const getOrbStyle = (status: ModerationFlag) => {
  switch (status) {
    case ModerationFlag.OK:
      return { backgroundColor: "#4caf50" }
    case ModerationFlag.WARNED:
      return { backgroundColor: "#ff9800" }
    case ModerationFlag.BLOCKED:
      return { backgroundColor: "#f44336" }
    case ModerationFlag.ACTION_REQUIRED:
      return { backgroundColor: "#f44336" }
    default:
      return { backgroundColor: "transparent" }
  }
}

export const getOrbText = (status: ModerationFlag) => {
  switch (status) {
    case ModerationFlag.OK:
      return "Все хорошо"
    case ModerationFlag.WARNED:
      return "Предупрежден"
    case ModerationFlag.BLOCKED:
      return "Заблокирован"
    case ModerationFlag.ACTION_REQUIRED:
      return "Требуется действие"
  }
}

export function getCityNameByIata(iata: string): string {
  switch (iata) {
    case "NYC":
      return "New York"
    case "MOW":
      return "Москва"
    case "LED":
      return "Питер"
    case "KZN":
      return "Казань"
    case "SVX":
      return "Екатеринбург"
    case "ROV":
      return "Ростов-на-Дону"
    case "GOJ":
      return "Нижний Новгород"
    case "VOZ":
      return "Воронеж"
    case "KUF":
      return "Самара"
    case "OVB":
      return "Новосибирск"
    case "KGD":
      return "Калининград"
    default:
      return "Неактивный город"
  }
}
