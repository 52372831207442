import { putHeaders } from "./storage"
import axios from "./axios"
import { AxiosResponse } from "axios"
import { Counselor } from "../types/entity"

export default class CounselorsApi {
  static async getCounselorDetails(
    id: string
  ): Promise<AxiosResponse<{ result: Counselor }>> {
    try {
      const response = await axios.get(`/admin/counselors/details/${id}`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get counselor`, e)
      throw e
    }
  }

  static async getCounselors() {
    try {
      const response = await axios.get("/admin/counselors", {
        headers: putHeaders(),
      })

      return response.data
    } catch (e) {
      console.error(`Cannot get counselors`, e)
      throw e
    }
  }

  static async createCounselor(
    data: FormData
  ): Promise<AxiosResponse<{ result: Counselor }>> {
    try {
      const response = await axios.post(`/admin/counselors/create`, data, {
        headers: putHeaders(),
      })

      return response
    } catch (e) {
      console.error(`Cannot create counselor`, e)
      throw e
    }
  }

  static async updateCounselor(
    data: FormData,
    id: string
  ): Promise<AxiosResponse<{ result: Counselor }>> {
    try {
      const response = await axios.post(
        `/admin/counselors/update/${id}`,
        data,
        {
          headers: putHeaders(),
        }
      )

      return response
    } catch (e) {
      console.error(`Cannot update counselor`, e)
      throw e
    }
  }

  static async deleteCounselor(
    id: string
  ): Promise<AxiosResponse<{ result: Counselor }>> {
    try {
      const response = await axios.post(
        `/admin/counselors/remove/${id}`,
        {},
        {
          headers: putHeaders(),
        }
      )

      return response
    } catch (e) {
      console.error(`Cannot delete counselor`, e)
      throw e
    }
  }
}
