import styled from "styled-components"

interface Props {
  text: string
  active?: boolean
  marginBottom?: string
  ml?: string
  width?: string

  onClick: () => void
}

const Container = styled.div`
  padding: 16px 24px;
  font-size: 20px;
  line-height: 28px;
  color: #979797;
  border-radius: 100px;
  background: #ededed;
`

export default function Button(props: Props) {
  return (
    <Container
      style={{
        width: props.width ? props.width : "auto",
        color: props.active ? "black" : "#979797",
        marginLeft: props.ml,
        marginBottom: props.marginBottom,
        cursor: props.active ? "pointer" : "default",
      }}
      onClick={() => (props.active ? props.onClick() : null)}
    >
      {props.text}
    </Container>
  )
}
