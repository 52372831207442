import { useState, useEffect } from "react"
import {
  FeedbackType,
  EntityItem,
  ReportType,
  ModerationFlag,
} from "../types/entity"
import ReportsApi from "../api/ReportsApi"
import EntityList from "./EntityList"
import { toastError } from "../toast"

type EntityProps = {
  type: FeedbackType
  selectedItem: ReportType | null
  onSelect(item: ReportType | null): void
}

const FeedBackEntity: React.FC<EntityProps> = ({
  type,
  onSelect,
  selectedItem,
}) => {
  const [items, setItems] = useState<EntityItem[]>([])
  const [reports, setReports] = useState<ReportType[]>([])
  const [cursor, setCursor] = useState<string | undefined>(undefined)
  const [isLast, setIsLast] = useState<boolean>(true)
  const [resetState, setResetState] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    reset()
  }, [type])

  useEffect(() => {
    if (resetState) {
      firstApiCall()
    }
  }, [resetState])

  // API CALLS

  const firstApiCall = async () => {
    setResetState(false)
    switch (type) {
      case FeedbackType.USERS: {
        await getUserReports()
        return
      }
      case FeedbackType.CUSTOM_EVENTS: {
        await getCustomEventReports()
        return
      }
      case FeedbackType.EVENTS: {
        await getOrganizationEventReports()
        return
      }
      case FeedbackType.ORGANIZATIONS: {
        await getOrganizationReports()
        return
      }
      case FeedbackType.MEETING: {
        await getMeetingReports()
        return
      }
      case FeedbackType.DELETE: {
        await getDestroyReports()
        return
      }
      default:
        return
    }
  }

  const getOrganizationReports = async () => {
    setLoading(true)
    try {
      const response = await ReportsApi.getOrganizationReports(cursor)
      const mappedItems = response.data.results.map((item) => {
        return {
          id: item.id,
          moderationFlag: ModerationFlag.OK,
          text: item.reported_organization.name,
        }
      })
      setCursor(response.data.cursor)
      setIsLast(response.data.is_last)
      setItems([...items, ...mappedItems])
      setReports([...reports, ...response.data.results])
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  const getUserReports = async () => {
    setLoading(true)
    try {
      const response = await ReportsApi.getUsersReports(cursor)
      const mappedItems = response.data.results.map((item) => {
        return {
          id: item.id,
          moderationFlag: ModerationFlag.OK,
          text:
            item.reported_user.phone || item.reported_user.first_name || "User",
        }
      })
      setCursor(response.data.cursor)
      setIsLast(response.data.is_last)
      setItems([...items, ...mappedItems])
      setReports([...reports, ...response.data.results])
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  const getCustomEventReports = async () => {
    setLoading(true)
    try {
      const response = await ReportsApi.getCustomEventsReports(cursor)
      const mappedItems = response.data.results.map((item) => {
        return {
          id: item.id,
          moderationFlag: ModerationFlag.OK,
          text: item.reported_event.title,
        }
      })

      setCursor(response.data.cursor)
      setIsLast(response.data.is_last)
      setItems([...items, ...mappedItems])
      setReports([...reports, ...response.data.results])
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  const getOrganizationEventReports = async () => {
    setLoading(true)
    try {
      const response = await ReportsApi.getOrganizationEventsReports(cursor)
      const mappedItems = response.data.results.map((item) => {
        return {
          id: item.id,
          moderationFlag: ModerationFlag.OK,
          text: item.reported_event.title,
        }
      })
      setCursor(response.data.cursor)
      setIsLast(response.data.is_last)
      setItems([...items, ...mappedItems])
      setReports([...reports, ...response.data.results])
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  const getMeetingReports = async () => {
    setLoading(true)
    try {
      const response = await ReportsApi.getMeetings(cursor)
      const mappedItems = response.data.results.map((item) => {
        const author = item.user.first_name || "Юзер"
        const invitee = item.invited_user.first_name || "Юзер"
        return {
          id: item.id,
          moderationFlag: ModerationFlag.OK,
          text: `${author} и ${invitee}`,
        }
      })
      setCursor(response.data.cursor)
      setIsLast(response.data.is_last)
      setItems([...items, ...mappedItems])
      setReports([...reports, ...response.data.results])
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  const getDestroyReports = async () => {
    setLoading(true)
    try {
      const response = await ReportsApi.getDestroyReports(cursor)
      const mappedItems = response.data.results.map((item) => {
        return {
          id: item.id,
          moderationFlag: ModerationFlag.OK,
          text: item.phone || item.first_name || "User",
        }
      })
      setCursor(response.data.cursor)
      setIsLast(response.data.is_last)
      setItems([...items, ...mappedItems])
      setReports([...reports, ...response.data.results])
    } catch (e) {
      toastError(e)
    } finally {
      setLoading(false)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////

  //Utils

  const getReportById = (id: string) => {
    const found = reports.find((item) => item.id === id)
    return found ? found : null
  }

  const reset = () => {
    setItems([])
    setReports([])
    setCursor(undefined)
    setIsLast(true)
    setResetState(true)
  }

  ///////////////////////////////////////////////////////////////

  if (loading) {
    return <div>Loading...</div>
  }

  if (type === FeedbackType.USERS) {
    return (
      <EntityList
        active={selectedItem?.id}
        items={items}
        isLast={isLast}
        loadMore={getUserReports}
        onSelect={(item) => onSelect(getReportById(item.id))}
      />
    )
  }
  if (type === FeedbackType.CUSTOM_EVENTS) {
    return (
      <EntityList
        active={selectedItem?.id}
        items={items}
        isLast={isLast}
        loadMore={getCustomEventReports}
        onSelect={(item) => onSelect(getReportById(item.id))}
      />
    )
  }
  if (type === FeedbackType.EVENTS) {
    return (
      <EntityList
        active={selectedItem?.id}
        items={items}
        isLast={isLast}
        loadMore={getOrganizationEventReports}
        onSelect={(item) => onSelect(getReportById(item.id))}
      />
    )
  }
  if (type === FeedbackType.ORGANIZATIONS) {
    return (
      <EntityList
        active={selectedItem?.id}
        items={items}
        isLast={isLast}
        loadMore={getOrganizationReports}
        onSelect={(item) => onSelect(getReportById(item.id))}
      />
    )
  }
  if (type === FeedbackType.MEETING) {
    return (
      <EntityList
        active={selectedItem?.id}
        items={items}
        isLast={isLast}
        loadMore={getMeetingReports}
        onSelect={(item) => onSelect(getReportById(item.id))}
      />
    )
  }
  if (type === FeedbackType.DELETE) {
    return (
      <EntityList
        active={selectedItem?.id}
        items={items}
        isLast={isLast}
        loadMore={getDestroyReports}
        onSelect={(item) => onSelect(getReportById(item.id))}
      />
    )
  }
  return <div>hello</div>
}

export default FeedBackEntity
