import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { toastError } from "../toast"
import StatsApi from "../api/StatsApi"

const Container = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

const SimpleDataContainer = styled.div`
  border: 1px solid black;
  border-radius: 24px;
  padding: 1rem;
  font-size: 1.1rem;
  margin-bottom: 1rem;
`

export default function Graphs() {
  const [usersIncome, setUsersIncome] = useState<
    Highcharts.Options | undefined
  >()
  const [usersActivity, setUsersActivity] = useState<
    Highcharts.Options | undefined
  >()
  const [activeUsers, setActiveUsers] = useState<
    Highcharts.Options | undefined
  >()
  const [usersContactsSynced, setUsersContactsSynced] = useState<number>(0)
  const [usersPositiveFeedback, setUsersPositiveFeedback] = useState<number>(0)

  const loadSimpleData = async () => {
    try {
      const response = await StatsApi.getSimpleData()

      setUsersContactsSynced(response.data.users_contacts_synced)
      setUsersPositiveFeedback(response.data.positive_feedbacks)
    } catch (e) {
      toastError(e)
      console.error(e)
    }
  }

  const loadUsersIncome = async () => {
    try {
      const response = await StatsApi.getUsersIncomeByWeek()

      setUsersIncome(response.data.chart)

      console.log("Data", response.data)
    } catch (e) {
      toastError(e)
      console.error(e)
    }
  }

  const loadUsersActivity = async () => {
    try {
      const response = await StatsApi.getUsersActivityByWeek()

      setUsersActivity(response.data.chart)

      console.log("Data", response.data)
    } catch (e) {
      toastError(e)
      console.error(e)
    }
  }

  const loadActiveUsers = async () => {
    try {
      const response = await StatsApi.getActiveUsersByWeek()

      setActiveUsers(response.data.chart)

      console.log("Data", response.data)
    } catch (e) {
      toastError(e)
      console.error(e)
    }
  }

  useEffect(() => {
    loadUsersIncome()
    loadUsersActivity()
    loadActiveUsers()
    loadSimpleData()
  }, [])

  return (
    <React.Fragment>
      <Container>
        <SimpleDataContainer>
          <span>{`Всего пользователей, синхронизировавших контакты: `}</span>
          <span style={{ fontWeight: "bold" }}>{usersContactsSynced}</span>
        </SimpleDataContainer>
        <SimpleDataContainer>
          <span>{`Всего отзывов о встречах: `}</span>
          <span style={{ fontWeight: "bold" }}>{usersPositiveFeedback}</span>
        </SimpleDataContainer>
        {activeUsers ? (
          <HighchartsReact highcharts={Highcharts} options={activeUsers} />
        ) : null}
        {usersIncome ? (
          <HighchartsReact highcharts={Highcharts} options={usersIncome} />
        ) : null}
        {usersActivity ? (
          <HighchartsReact highcharts={Highcharts} options={usersActivity} />
        ) : null}
      </Container>
    </React.Fragment>
  )
}
