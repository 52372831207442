import { toast } from "react-toastify"

const toastError = (errors: any, title = "") => {
  if (errors?.response?.data?.errors) {
    errors = errors.response.data.errors
  } else {
    errors = []
  }

  if (errors.length === 0) {
    toast.error(title)
    return
  }

  if (Array.isArray(errors)) {
    const errMessage = errors.map((error: any) => error.message).join(",")
    const message = title ? `${title}: ${errMessage}` : errMessage
    toast.error(message)
  }
}

export { toastError }
