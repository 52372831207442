import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.24;
  cursor: pointer;
`

const Content = styled.div<{ width?: string }>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 150px;
  width: ${(props) => (props.width ? props.width : "500px")};
  background: #fff;
  border-radius: 50px;
  padding: 54px 129px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  font-size: 30px;
  color: black;
  margin-bottom: 20px;
  white-space: nowrap;
`
const Body = styled.div`
  margin-top: 10px;
`

type modalProps = {
  title: string
  onClose(): void
  width?: string
}

const ActionModal: React.FC<modalProps> = ({
  title,
  onClose,
  children,
  width,
}) => {
  return (
    <Wrap>
      <Background onClick={onClose} />
      <Content width={width}>
        <Header>{title}</Header>
        <Body>{children}</Body>
      </Content>
    </Wrap>
  )
}

export default ActionModal
