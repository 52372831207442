import React, { useState, useContext } from "react"
import AppContext from "../store/AppContext"
import styled from "styled-components"
import Button from "./Button"
import { Flex } from "./Flex"
import Input from "./MyInput"
import TextArea from "./MyTextArea"
import MyImage from "./MyImage"
import { toastError } from "../toast"
import { useHistory } from "react-router-dom"
import { Counselor, EntityItem, ModerationFlag } from "../types/entity"
import SearchWindow from "./SearchWindow"
import ChooseButton from "./ChooseButton"
import CounselorsApi from "../api/CounselorsApi"
import SearchApi from "../api/SearchApi"
import { SketchPicker } from "react-color"
import CounselorEvents from "./counselors/CounselorEvents"

const InputContainer = styled.div`
  width: 400px;
  height: 60px;
  margin-bottom: 22px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const StyledSelect = styled(InputContainer)`
  select {
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 14px 24px;
    border: 2px solid #ededed;
    border-radius: 30px;
    background: transparent;
    font-size: 20px;
    color: black;
    resize: none;
    font-family: "Open Sans", sans-serif;
    :placeholder {
      color: #979797;
    }
  }
`

const TextAreaContainer = styled.div`
  width: 400px;
  height: 160px;
  margin-bottom: 22px;
  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1200px) {
    width: 250px;
  }
`

const CheckBoxContainer = styled.div`
  margin-bottom: 40px;
  margin-left: 46px;
`
const ImageContainerSmall = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media (max-width: 1400px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 1200px) {
    width: 75px;
    height: 75px;
  }
`

const Header = styled.h1`
  margin-left: 30px;
  margin-bottom: 44px;
`

const CreateContainer = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

const SampleChip = styled.div`
  border-radius: 24px;
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.4rem;
`

type CounselorMediaType = "image"

const CreateCounselor: React.FC = () => {
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [formData, setFormData] = useState(
    new Map<CounselorMediaType, Blob | null>()
  )
  const history = useHistory()
  const [form, setForm] = useState<Partial<Counselor>>({
    title: "",
    instagram: "",
    description: "",
    url: "",
    image_url: "",
    active_until: new Date("09/09/2099"),
    text_color: "#FFFFFF",
    color: "#000000",
  })
  const [events, setEvents] = useState<EntityItem[]>([])
  const [isLanding, setIsLanding] = useState<boolean>(true)
  const { setChooseWindow } = useContext(AppContext)

  const fileHandler = (value: Blob | null, type: CounselorMediaType) => {
    setFormData(formData.set(type, value))
  }

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const prepareForm = (): Record<string, any> => {
    const deletion: string[] = ["image_url"]
    const f = {
      ...form,
      event_ids: events.map((ev) => ev.id),
    }

    return (Object.keys(f) as Array<keyof typeof f>)
      .filter((key) => !deletion.includes(key))
      .reduce<any>((acc, key) => {
        if (f[key] === undefined) {
          return acc
        }
        if (key === "event_ids") {
          acc[key] = JSON.stringify(f[key])
          return acc
        }
        if (key === "active_until") {
          const val = f[key] as Date
          acc[key] = new Date(val).toISOString()
          return acc
        }
        acc[key] = f[key]
        return acc
      }, {})
  }

  const onCreate = async () => {
    try {
      setDisableSubmit(true)
      const newForm = prepareForm()
      console.log(`Creating formdata from form`, newForm)
      const submitFormData = generateFormData(newForm)
      const response = await CounselorsApi.createCounselor(submitFormData)
      history.push(`/counselors/${response.data.result.id}`)
      setDisableSubmit(false)
    } catch (e) {
      console.error("Error", e)
      toastError(e)
      setDisableSubmit(false)
    }
  }

  const generateFormData = (f: Record<string, any>): FormData => {
    const newFormData = new FormData()
    const image = formData.get("image")
    if (image) {
      console.log(`Adding key image`)
      newFormData.append("image", image)
    }
    Object.keys(f).forEach((k) => {
      console.log(`Adding key ${k} with value ${f[k]}`)
      newFormData.append(k, f[k])
    })
    return newFormData
  }

  const searchEvent = async (query: string) => {
    try {
      const response = await SearchApi.searchOrganizationEvents(query)

      return response.data.results.map((v) => ({
        id: v.id,
        text: v.title,
        moderationFlag: v.deleted_at
          ? ModerationFlag.BLOCKED
          : ModerationFlag.OK,
      }))
    } catch (e) {
      console.error(`Cannot select categories`, e)
      toastError(e)
      return []
    }
  }

  const pickEvent = () => {
    const onClose = (item: EntityItem | null) => {
      setChooseWindow(null)
      if (!item) {
        return
      }
      setForm({ ...form })
      setEvents([...events, item])
    }
    const JSX = (
      <SearchWindow
        title="Событие"
        onSearch={searchEvent}
        onClose={(item) => onClose(item)}
      />
    )
    setChooseWindow(JSX)
  }

  return (
    <CreateContainer>
      <Header>Создать рекомендацию</Header>
      <Flex>
        <Flex direction="column">
          <ImagesContainer>
            <ImageContainerSmall>
              <MyImage
                imgSrc={undefined}
                onUpload={(value) => fileHandler(value, "image")}
                imgTitle="Картинка"
              />
            </ImageContainerSmall>
          </ImagesContainer>
          <StyledSelect>
            <select
              value={isLanding ? "1" : "0"}
              onChange={(v) => {
                console.log(`Value ${v.target.value}`)
              }}
              name="isLanding"
            >
              <option value="1">Лендинг</option>
            </select>
          </StyledSelect>
          <InputContainer>
            <Input
              name="title"
              value={form.title}
              onChange={changeHandler}
              placeholder="Заголовок"
            />
          </InputContainer>

          <InputContainer>
            <Input
              name="url"
              onChange={changeHandler}
              value={form.url || ""}
              placeholder="Ссылка на лендинг"
            />
          </InputContainer>
          <InputContainer>
            <Input
              name="instagram"
              value={form.instagram || ""}
              onChange={changeHandler}
              placeholder="Инстаграм"
            />
          </InputContainer>
          {!isLanding ? (
            <TextAreaContainer>
              <TextArea
                value={form.description || ""}
                name="description"
                onChange={changeHandler}
                placeholder="Описание"
              />
            </TextAreaContainer>
          ) : null}
          <CounselorEvents
            events={events}
            onChange={(ev) => {
              setEvents(ev)
            }}
          />
          <h3>Превью</h3>
          <Flex>
            <SampleChip
              style={{
                backgroundColor: form.color,
                color: form.text_color,
                marginBottom: 20,
              }}
            >
              {form.title || "Выбор Вовы"}
            </SampleChip>
          </Flex>
          <Flex>
            <div>
              <div>Цвет фона</div>
              <SketchPicker
                disableAlpha
                color={form.color}
                onChange={(color) => {
                  setForm({
                    ...form,
                    color: color.hex,
                  })
                }}
              />
            </div>
            <div>
              <div>Цвет текста</div>
              <SketchPicker
                disableAlpha
                color={form.text_color}
                onChange={(color) => {
                  setForm({
                    ...form,
                    text_color: color.hex,
                  })
                }}
              />
            </div>
          </Flex>
        </Flex>
      </Flex>
      <Flex mt="20px">
        <Button text="Создать" active={!disableSubmit} onClick={onCreate} />
      </Flex>
    </CreateContainer>
  )
}

export default CreateCounselor
