import React, { useEffect, useState } from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import CounselorsApi from "../api/CounselorsApi"
import CounselorData from "../components/Counselor"
import EntityList from "../components/EntityList"
import { Flex } from "../components/Flex"
import { toastError } from "../toast"
import { Counselor, EntityItem, ModerationFlag } from "../types/entity"

const Container = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

export default function Counselors() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [items, setItems] = useState<EntityItem[]>([])

  const loadElements = async () => {
    try {
      const data = await CounselorsApi.getCounselors()
      setItems([...items, ...mapToEntityList(data.results)])
    } catch (e) {
      toastError(e)
    }
  }

  const mapToEntityList = (items: Counselor[]) => {
    return items.map((item) => {
      return {
        id: item.id,
        moderationFlag: item.deleted_at
          ? ModerationFlag.BLOCKED
          : ModerationFlag.OK,
        text: item.title,
      }
    })
  }

  useEffect(() => {
    loadElements()
  }, [])

  return (
    <React.Fragment>
      <Flex direction="column">
        <EntityList
          isLast={true}
          loadMore={loadElements}
          items={items}
          onSelect={(item) => {
            console.log(`Item is`, item)
            history.push(`${path}/${item.id}`)
          }}
        />
      </Flex>
      <Container>
        <Switch>
          <Route exact path={path}>
            <h1 style={{ textAlign: "center" }}>
              Хочу я замуж, замуж хочу, да ты не бойся, я все оплачу...
            </h1>
          </Route>
          <Route path={`${path}/:id`}>
            <CounselorData />
          </Route>
        </Switch>
      </Container>
    </React.Fragment>
  )
}
