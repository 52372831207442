import React, { useEffect, useRef, useState } from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import OrganizationApi from "../api/OrganizationApi"
import SearchApi from "../api/SearchApi"
import EntityList from "../components/EntityList"
import { Flex } from "../components/Flex"
import Organization from "../components/Organization"
import SearchInput from "../components/SearchInput"
import { toastError } from "../toast"
import { EntityItem, ModerationFlag, OrganizationData } from "../types/entity"

const Container = styled.div`
  flex: 2;
  border-radius: 50px 0 0 50px;
  background: white;
  padding: 30px 50px;
  overflow-y: auto;
`

export default function Organizations() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [cursor, setCursor] = useState<string>()
  const [isLast, setIsLast] = useState<boolean>(true)
  const [items, setItems] = useState<EntityItem[]>([])
  const mounted = useRef(true)

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const loadElements = async () => {
    try {
      const data = await OrganizationApi.getOrganizations(cursor)

      console.log(`Data.results`, data.results)
      setCursor(data.next_cursor)
      setIsLast(data.is_last)
      setItems([...items, ...mapToEntityList(data.results)])
    } catch (e) {
      toastError(e)
    }
  }

  const initialLoad = async () => {
    try {
      const data = await OrganizationApi.getOrganizations(cursor)
      if (mounted.current) {
        setCursor(data.next_cursor)
        setIsLast(data.is_last)
        setItems(mapToEntityList(data.results))
      }
    } catch (e) {
      toastError(e)
    }
  }
  const searchElements = async (value: string) => {
    try {
      const data = await SearchApi.searchOrganizations(value)
      setCursor(undefined)
      setIsLast(true)
      setItems(mapToEntityList(data.data.results))
    } catch (e) {
      toastError(e)
    }
  }

  const mapToEntityList = (items: OrganizationData[]) => {
    return items.map((item) => {
      return {
        id: item.id,
        moderationFlag: item.deleted_at
          ? ModerationFlag.BLOCKED
          : ModerationFlag.OK,
        text: item.name,
      }
    })
  }

  return (
    <React.Fragment>
      <Flex direction="column">
        <SearchInput initialLoad={initialLoad} query={searchElements} />
        <EntityList
          isLast={isLast}
          loadMore={loadElements}
          items={items}
          onSelect={(item) => {
            console.log(`Item is`, item)
            history.push(`${path}/${item.id}`)
          }}
        />
      </Flex>
      <Container>
        <Switch>
          <Route exact path={path}>
            <h1 style={{ textAlign: "center" }}>
              Хабатаи-и та ла, модорана-ито-итте, мезащита но-о ва-а, АОИ, АОИ,
              ано сора
            </h1>
          </Route>
          <Route path={`${path}/:id`}>
            <Organization />
          </Route>
        </Switch>
      </Container>
    </React.Fragment>
  )
}
