import styled from "styled-components"
import { ImCross } from "react-icons/im"
import { BiTime } from "react-icons/bi"
import { DateTime } from "luxon"
import DayPickerInput from "react-day-picker/DayPickerInput"

type Props = {
  onChange: (value: string[]) => void
  value: string[]
  maximumDates: number
  title: string
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 14px 24px;
    border: 2px solid #ededed;
    border-radius: 30px;
    background: transparent;
    font-size: 20px;
    color: black;
    resize: none;
    font-family: "Open Sans", sans-serif;
  }
  .DayPickerInput {
    width: 100%;
  }
  margin-bottom: 20px;
`

const ActionButton = styled.div`
  border-radius: 16px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  margin-left: 1rem;
`

const RemoveButton = styled(ActionButton)`
  background-color: #c05151;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  :hover {
    border: 2px solid black;
    transition: 0.2s;
  }
`

const AddButtonContainer = styled.div`
  background-color: black;
  border-radius: 32px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 0.75rem;
  cursor: pointer;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin: 1rem;
`

const MultipleDatePicker: React.FC<Props> = ({
  value,
  onChange,
  maximumDates,
  title,
}) => {
  const onAdd = () => {
    onChange([...value, DateTime.now().endOf("day").toISO()])
  }

  const onRemove = (index: number) => {
    const newValues = value.filter((v, i) => i !== index)
    onChange(newValues)
  }

  const onEdit = (val: string, index: number) => {
    const newValues = value.map((v, i) => {
      if (index === i) {
        return val
      }
      return v
    })
    onChange(newValues)
  }
  const formatDate = (v: Date, format: string) => {
    return DateTime.fromJSDate(v).toFormat(format)
  }

  return (
    <Container>
      <Title>{title}</Title>
      {value.map((d: string, i) => (
        <DateContainer key={i}>
          <DayPickerInput
            value={formatDate(new Date(d), "dd.MM.yyyy")}
            onDayChange={(date) =>
              onEdit(DateTime.fromJSDate(date).endOf("day").toISO(), i)
            }
            inputProps={{ readOnly: true }}
          />
          <RemoveButton onClick={() => onRemove(i)}>
            <ImCross color="#ffffff" />
          </RemoveButton>
        </DateContainer>
      ))}
      {value.length < maximumDates ? (
        <AddButtonContainer onClick={onAdd}>
          <BiTime size={18} />
          <span style={{ marginLeft: 12 }}>Добавить дату</span>
        </AddButtonContainer>
      ) : null}
    </Container>
  )
}

export default MultipleDatePicker
