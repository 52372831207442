import { AxiosResponse } from "axios"
import { ChatMessage, UserData } from "../types/entity"
import axios from "./axios"
import { putHeaders } from "./storage"

export type GetSupportChatItem = {
  id: string
  is_read: boolean
  text: string
  user: UserData
  from_support: boolean
  created_at: Date
}

type GetSupportChatsResponse = {
  is_last: boolean
  next_cursor: string
  results: GetSupportChatItem[]
}

export default class ChatApi {
  static async getMessages(
    userId: string,
    cursor?: string
  ): Promise<
    AxiosResponse<{
      is_last: boolean
      next_cursor: string
      results: ChatMessage[]
    }>
  > {
    try {
      const response = await axios.get(
        `/admin/chats/support/messages/${userId}`,
        {
          params: {
            ...(cursor ? { cursor } : {}),
          },
          headers: putHeaders(),
        }
      )
      return response
    } catch (e) {
      console.error(e)
      throw e
    }
  }
  static async sendMessage(id: string, text: string) {
    try {
      const response = await axios.post(
        `/admin/chats/support/messages/create/${id}/text`,
        { text },
        { headers: putHeaders() }
      )
      return response
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  static async getSupportChats(
    cursor?: string
  ): Promise<AxiosResponse<GetSupportChatsResponse>> {
    try {
      const response = await axios.get(`/admin/chats/support/list`, {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(e)
      throw e
    }
  }
}
