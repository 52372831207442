const colors = {
  yellow: "#FFE600",
  green: "#AEE143",
  purple: "#CCCBFF",
  pink: "#FF93B3",
  brown: "#CDB694",
  grey: "#DCDCDC",
  orange: "#FF5C00",
}

export default colors
