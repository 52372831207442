import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import SearchApi from "../../api/SearchApi"
import AppContext from "../../store/AppContext"
import { toastError } from "../../toast"
import { EntityItem, ModerationFlag } from "../../types/entity"
import MultipleEventPicker from "../MultipleEventPicker"
import SearchWindow from "../SearchWindow"
import EditValueWindow from "../EditValueWindow"
import EventsApi from "../../api/EventsApi"

type Props = {
  events: EntityItem[]
  onChange: (v: EntityItem[]) => void
  onSubmitPriority?: (v: EntityItem, val: number) => void
}

export default function CounselorEvents(props: Props) {
  const { setChooseWindow } = useContext(AppContext)
  const history = useHistory()

  const searchEvent = async (query: string): Promise<EntityItem[]> => {
    try {
      const response = await SearchApi.searchOrganizationEvents(query)

      return response.data.results.map((v) => ({
        id: v.id,
        text: v.title,
        moderationFlag: v.deleted_at
          ? ModerationFlag.BLOCKED
          : ModerationFlag.OK,
      }))
    } catch (e) {
      console.error(`Cannot select event`, e)
      toastError(e)
      return []
    }
  }

  const pickEvent = () => {
    const onClose = (item: EntityItem | null) => {
      setChooseWindow(null)
      if (!item) {
        return
      }
      props.onChange([...props.events, item])
    }
    const JSX = (
      <SearchWindow
        title="Событие"
        onSearch={searchEvent}
        onClose={(item) => onClose(item)}
      />
    )
    setChooseWindow(JSX)
  }

  const editPriority = (item: EntityItem) => {
    const onClose = () => {
      setChooseWindow(null)
    }

    const JSX = (
      <EditValueWindow
        type="number"
        onClose={onClose}
        onChange={() => {}}
        onSubmit={(v) => {
          props.onSubmitPriority!(item, Number(v))
        }}
        title="Установить приоритет"
      />
    )
    setChooseWindow(JSX)
  }

  return (
    <React.Fragment>
      <MultipleEventPicker
        onAdd={pickEvent}
        events={props.events}
        onChange={(val) => {
          props.onChange(val)
        }}
        onClick={(val) => {
          history.push(`/organization_events/${val.id}`)
        }}
        maximumEvents={50}
        title="События"
        {...(props.onSubmitPriority ? { onEditPriority: editPriority } : {})}
      />
    </React.Fragment>
  )
}
