import React, { useContext, useState } from "react"
import { Flex } from "../Flex"
import Button from "../Button"
import ActionModal from "../ActionModal"
import AppContext from "../../store/AppContext"
import styled from "styled-components"

const ModalButton = styled.span<{ active?: boolean }>`
  margin: 14px 50px;
  font-size: 24px;
  cursor: pointer;
  color: ${(props) => (props.active ? "#000" : "#c3c3c3")};
`

const ModalChoice = styled(ModalButton)`
  margin: 0 50px;
  margin-top: 50px;
  color: #000;
  cursor: ${(props) => (props.active ? "pointer" : "default")};
`

const gifts = [
  { label: "На неделю", value: "week" },
  { label: "На месяц", value: "month" },
  { label: "На полгода", value: "halfyear" },
  { label: "На год", value: "year" },
  { label: "Навсегда", value: "forever" },
]

const Modal: React.FC<{ onClose(): void; onYes(choice: string): void }> = ({
  onClose,
  onYes,
}) => {
  const [choice, setChoice] = useState<string | null>(null)
  return (
    <ActionModal onClose={onClose} title="Подарить подписку">
      <Flex direction="column" align="center">
        {gifts.map((gift, index) => {
          return (
            <ModalButton
              active={gift.value === choice}
              onClick={() => setChoice(gift.value)}
              key={index}
            >
              {gift.label}
            </ModalButton>
          )
        })}
      </Flex>
      <Flex>
        <ModalChoice onClick={onClose}>Закрыть</ModalChoice>
        <ModalChoice
          active={!!choice}
          onClick={() => {
            if (!choice) return
            onYes(choice)
          }}
        >
          Выполнить
        </ModalChoice>
      </Flex>
    </ActionModal>
  )
}

const GiftButton: React.FC<{ id: string; active: boolean; reload(): void }> = ({
  id,
  active,
  reload,
}) => {
  const { setChooseWindow } = useContext(AppContext)

  const giftRequest = (value: string) => {
    /// API
    alert(value)
  }

  const subscriptionGift = () => {
    const JSX = (
      <Modal onClose={() => setChooseWindow(null)} onYes={giftRequest} />
    )
    setChooseWindow(JSX)
  }

  return (
    <Button
      active={active}
      text="Подарить подписку"
      onClick={subscriptionGift}
    />
  )
}

export default GiftButton
