import { AxiosResponse } from "axios"
import { EventCategoriesMetadata } from "../types/entity"
import axios from "./axios"
import { putHeaders } from "./storage"

export default class CategoriesApi {
  static async getAllCategories(): Promise<
    AxiosResponse<{
      result: EventCategoriesMetadata
    }>
  > {
    try {
      const response = await axios.get("/admin/categories", {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get categories`, e)
      throw e
    }
  }
}
