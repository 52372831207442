import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"

type InputProps = InputHTMLAttributes<HTMLInputElement> & {}

const InputContainer = styled.input`
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 14px 24px;
  border: 2px solid #ededed;
  border-radius: 30px;
  background: transparent;
  font-size: 20px;
  color: black;
  resize: none;
  font-family: "Open Sans", sans-serif;
  :placeholder {
    color: #979797;
  }
`

const Input: React.FC<InputProps> = ({ ...props }) => {
  return <InputContainer {...props} />
}

export default Input
