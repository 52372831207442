import { putHeaders } from "./storage"
import axios from "./axios"
import { AxiosResponse } from "axios"
import { UserData } from "../types/entity"

export default class UserApi {
  static async refreshAdaptyUser(user_id: string) {
    try {
      const response = await axios.post(
        `/admin/users/adapty_refresh/${user_id}`,
        {},
        {
          headers: putHeaders(),
        }
      )
      return response
    } catch (e) {
      console.error("Cannot refresh adapty user", e)
      throw e
    }
  }

  static async markCheckedUser(user_id: string) {
    try {
      const response = await axios.post(
        `/admin/users/unchecked/check/${user_id}`,
        {},
        {
          headers: putHeaders(),
        }
      )
      return response
    } catch (e) {
      console.error("Cannot mark checked user", e)
      throw e
    }
  }

  static async getUncheckedUsers(cursor?: string) {
    try {
      const response = await axios.get("/admin/users/unchecked", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })

      return response.data
    } catch (e) {
      console.error(`Cannot get unchecked users`, e)
      throw e
    }
  }

  static async getUsers(cursor?: string) {
    try {
      const response = await axios.get("/admin/list/users", {
        params: {
          ...(cursor ? { cursor } : {}),
        },
        headers: putHeaders(),
      })

      return response.data
    } catch (e) {
      console.error(`Cannot get users`, e)
      throw e
    }
  }
  static async getUser(
    id: string
  ): Promise<AxiosResponse<{ result: UserData }>> {
    try {
      const response = await axios.get(`/admin/users/details/${id}`, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error(`Cannot get user`, e)
      throw e
    }
  }
  static async blockUser(
    id: string,
    data: { reason: string; blocked_until: string }
  ) {
    try {
      const response = await axios.post(`/admin/users/block/${id}`, data, {
        headers: putHeaders(),
      })
      return response
    } catch (e) {
      console.error("Cannot block user", e)
      throw e
    }
  }
  static async unblockUser(id: string, reason: string) {
    try {
      const response = await axios.post(
        `/admin/users/unblock/${id}`,
        {
          reason,
        },
        {
          headers: putHeaders(),
        }
      )
      return response
    } catch (e) {
      console.error("Cannot unblock user", e)
      throw e
    }
  }
}
